import { loadStripe } from '@stripe/stripe-js/pure'
import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { AliceCardFragment } from '../../gql/connectedCardsQuery_gen'
import { useEphemeralKeySecretQuery } from './gql/ephemeralKeySecret_gen'
import { PRIMARY_FONT_FAMILY } from '@alice-financial/pretext-ui'

const CARD_DETAIL_STYLE = {
  base: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '16px',
    fontFamily: `'${PRIMARY_FONT_FAMILY}', -apple-system, sans-serif`,
  },
}

const loadNonNullStripe = (stripeAccount: string) =>
  loadStripe(process.env.REACT_APP_STRIPE_ISSUING_PUBLIC_KEY || '', { stripeAccount }).then((maybeStripe) => {
    if (!maybeStripe) throw new Error('Failed to load Stripe')
    return { stripe: maybeStripe, elements: maybeStripe.elements() }
  })
type StripePromise = ReturnType<typeof loadNonNullStripe>

const useNonceQuery = (stripePromise: StripePromise, issuingCard: string) =>
  useQuery(
    ['nonce', useEphemeralKeySecretQuery],
    () =>
      stripePromise
        .then(({ stripe }) => stripe.createEphemeralKeyNonce({ issuingCard }))
        .then((nonceResult) => nonceResult.nonce),
    { cacheTime: 0 } // always get new nonce
  )

type IssuingElementsData = {
  stripeAccount: string
  card: AliceCardFragment
  onClickNumberCopy?: () => void
  onClickCvcCopy?: () => void
}
export const useIssuingElements = ({
  stripeAccount,
  card,
  onClickNumberCopy,
  onClickCvcCopy,
}: IssuingElementsData) => {
  if (!card.bankingSystemId) throw new Error('Card is not an issuing card')
  const issuingCard = card.bankingSystemId
  const stripePromise = React.useRef(loadNonNullStripe(stripeAccount)).current
  const { data: nonce } = useNonceQuery(stripePromise, issuingCard)
  const { data: aliceCardData } = useEphemeralKeySecretQuery(
    { cardId: card.id, nonce: nonce || '' },
    { enabled: Boolean(nonce) }
  )
  const ephemeralKeySecret = aliceCardData?.employee?.spendingConnectionInfo.aliceCardAccounts?.find(
    (acct) => acct.aliceCard?.id === card.id
  )?.aliceCard?.ephemeralKeySecret

  return React.useMemo(() => {
    if (!ephemeralKeySecret) return null
    const getElements = stripePromise.then(({ elements }) => elements)
    const config = { issuingCard, ephemeralKeySecret, nonce, style: CARD_DETAIL_STYLE }

    return {
      mountNumber: getElements
        .then((elements) => elements.create('issuingCardNumberDisplay', config))
        .then((element) => element.mount),
      mountExpiry: getElements
        .then((elements) => elements.create('issuingCardExpiryDisplay', config))
        .then((element) => element.mount),
      mountCvc: getElements
        .then((elements) => elements.create('issuingCardCvcDisplay', config))
        .then((element) => element.mount),
      mountNumberCopy: getElements
        .then((elements) =>
          elements.create('issuingCardCopyButton', {
            toCopy: 'number',
            style: { base: { lineHeight: '44px', fontSize: '44px' } }, // 44px is approximately the height of the button
          })
        )
        .then((element) => (domElement: string | HTMLElement) => {
          element.mount(domElement)
          onClickNumberCopy && element.on('click', onClickNumberCopy)
        }),
      mountCvcCopy: getElements
        .then((elements) =>
          elements.create('issuingCardCopyButton', {
            toCopy: 'cvc',
            style: { base: { lineHeight: '44px', fontSize: '44px' } }, // 44px is approximately the height of the button
          })
        )
        .then((element) => (domElement: string | HTMLElement) => {
          element.mount(domElement)
          onClickCvcCopy && element.on('click', onClickCvcCopy)
        }),
    }
  }, [stripePromise, issuingCard, ephemeralKeySecret, nonce, onClickNumberCopy, onClickCvcCopy])
}
