import { TrnKey } from '../types'

export const SPANISH_TRNS: Record<TrnKey, string> = {
  'admin.enrollment.heading': 'Inscripción en beneficios',
  'auth.forgot_password': '¿Olvidaste tu contraseña?',
  'auth.forgot_password.instructions':
    'No hay problema. Sólo tienes que introducir tu dirección de correo electrónico a continuación. Te enviaremos un enlace para que puedas restablecer tu contraseña.',
  'auth.forgot_password.missing_reset_email':
    '¿No recibiste tu correo electrónico para restablecer tu cuenta? ¡Asegúrate de haber ingresado la misma dirección de correo electrónico con la que te inscribiste!',
  'auth.forgot_password.notification.requested_password':
    'Solicitud enviada - revisa tu correo electrónico para recibir más instrucciones.',
  'auth.forgot_password.notification.requesting_password':
    'Correo electrónico para solicitar restablecimiento de contraseña',
  'auth.login.code_sent':
    'Código enviado a <pii><strong-nowrap>{tel}</strong-nowrap></pii>. ¿No lo recibiste?',
  'auth.login.do_login': 'Iniciar sesión',
  'auth.login.get_code': 'Obtén un código de inicio de sesión',
  'auth.login.get_new_code': 'Obtén uno nuevo',
  'auth.login.logging_in': 'Iniciando sesión...',
  'auth.login.phone_verification': 'O, inicia sesión con tu teléfono',
  'auth.login.requesting_code': 'Solicitando un código',
  'auth.login.sign_in': 'Iniciar sesión',
  'auth.login.verification_code': 'Código de verificación',
  'auth.login.verifying_code': 'Verificando tu código',
  'auth.reset_password.heading': 'Restablece tu contraseña',
  'auth.reset_password.new_password_label': 'Nueva contraseña',
  'auth.reset_password.request_reset': 'Restablecer constraseña',
  'auth.reset_password.resetting_password': 'Restableciendo la contraseña...',
  'auth.reset_password.success':
    'Contraseña restablecida exitosamente - por favor inicia sesión para continuar',
  'auth.reset_password.validation.no_match': 'Las contraseñas deben coincidir',
  'auth.set_new_password.input_placeholder': 'Ingresa tu nueva contraseña aquí',
  'cards.alice.a_card_is_ready':
    'Tu Tarjeta Alice que termina en <strong>{last4}</strong> está lista para ser utilizada',
  'cards.alice.about_card_arrival':
    'Tu Tarjeta Alice llegará en 3 semanas. Podrás empezar a utilizar tu Tarjeta Alice cuando termines de inscribirte.',
  'cards.alice.apple-pay-terms': 'Términos de Apple Pay (Celtic Bank)',
  'cards.alice.arrival_date': 'Tu tarjeta física debería llegar antes de <strong>{arrivalDate}</strong>.',
  'cards.alice.balance_gated_account_info':
    'Tenemos toda la información que necesitamos para emitir tu nueva tarjeta. ¡Te notificaremos por texto o correo electrónico cuando esté lista!',
  'cards.alice.card_heading': 'Tarjeta Alice <strong>{cardType}</strong>',
  'cards.alice.card_initial_state':
    'Tu Tarjeta Alice viene lista para usar. No necesitas precargarla. No se necesitan recibos.',
  'cards.alice.confirm_you_read_the_documents': 'Debes confirmar que has leído todos los documentos',
  'cards.alice.digital_wallet.add_instant_card_to_digital_wallet':
    'Agrega tu tarjeta Instantánea a tu cartera digital',
  'cards.alice.digital_wallet.add_to_apple_pay_instructions':
    '<li>Ve a Wallet y presiona el botón de agregar (+)</li>\n    <li>Elige "Tarjeta de crédito o débito"</li>\n    <li>Copia el número de tu Tarjeta Alice instantánea o escanea los detalles de tu Tarjeta Alice física (también puedes elegir "Ingresar manualmente los datos")</li>\n    <li>Confirma tu Código de seguridad</li>\n    <li>Acepta los Términos y condiciones</li>',
  'cards.alice.digital_wallet.add_to_google_pay_instructions':
    '<li>Abre Google Pay</li>\n    <li>Presiona tu foto de perfil</li>\n    <li>Presiona "Cuentas de banco y tarjetas"</li>\n    <li>Presiona en "Agregar tarjeta"</li>\n    <li>Ingresa el número de la Tarjeta Alice, fecha de vencimiento, el CVV, tu nombre y domicilio</li>\n    <li>Acepta los Términos y condiciones</li>',
  'cards.alice.digital_wallet.add_your_alice_card_to_platform': 'Agrega tu Tarjeta Alice a {platform}',
  'cards.alice.digital_wallet.opening_instruction':
    'Para agregar tu Tarjeta Alice a Apple Pay o Google Pay solo copia la información de la Tarjeta Instantánea y pégala en tu Apple Pay o Android Pay.',
  'cards.alice.get_new_card': 'Solicitar tarjeta',
  'cards.alice.how_do_i_use_my_alice_card': '¿Cómo puedo utilizar mi Tarjeta Alice?',
  'cards.alice.i_have_read_agreements':
    'He leído y estoy de acuerdo con la <stripe-card-agreement></stripe-card-agreement>.',
  'cards.alice.instant.ready':
    'Puedes usar tu Tarjeta Alice Instantánea en línea o agregarla a tu cartera digital',
  'cards.alice.instant.show_details': 'Mostrar detalles',
  'cards.alice.list.heading': 'Mis tarjetas Alice',
  'cards.alice.list.summary':
    'Con la Tarjeta Alice puedes pagar los gastos de transporte al trabajo y acceder a tus fondos pre-impuestos directamente.',
  'cards.alice.loading': 'Buscando la información de tu cuenta de Tarjeta Alice ...',
  'cards.alice.order_instant_card':
    '¿Quieres una tarjeta instantánea que puedas usar en línea o desde tu billetera digital?',
  'cards.alice.order_instant_card_description': 'Revisa tus detalles y la generaremos de inmediato.',
  'cards.alice.order_my_alice_card': 'Ordenar mi Tarjeta Alice',
  'cards.alice.order_physical_card': '¿Quieres una tarjeta física que puedas usar en cualquier lugar?',
  'cards.alice.order_physical_card_description':
    'Revisa tus detalles y te enviaremos una. Debería llegar dentro de las 3 semanas.',
  'cards.alice.ordering_your_alice_card':
    'Confirma tus datos y emitiremos una tarjeta instantánea que podrás usar tan pronto como sea financiada por tu empleador. Cuando esté lista, podrás usarla en línea o desde tu billetera digital. También te enviaremos una tarjeta física que podrás usar en cualquier lugar; debería llegar dentro de las 3 semanas posteriores a tu solicitud.',
  'cards.alice.paying_using_alice_card':
    '<strong>Paga</strong> utilizando tu  Tarjeta Alice cuando gastes en los gastos elegibles descritos anteriormente.  Puedes deslizar, tocar o añadir tu  Tarjeta Alice a tu billetera de Apple/Google.',
  'cards.alice.relax_with_alice':
    '<strong>Relájate</strong> mientras Alice hace su magia y te envía un mensaje de confirmación tu ahorro.',
  'cards.alice.report_missing_label': 'Reportar perdida',
  'cards.alice.report_missing.card_was_mailed_to': 'Tu tarjeta ha sido enviada a',
  'cards.alice.report_missing.card_will_be_mailed_to': 'Tu tarjeta será  enviada a',
  'cards.alice.report_missing.change_address_label': 'Deseo actualizar mi dirección',
  'cards.alice.report_missing.heading': 'Reportar la pérdida de mi Tarjeta Alice',
  'cards.alice.report_missing.missing': 'La tarjeta fué extraviada',
  'cards.alice.report_missing.never_arrived': 'La tarjeta no llegó',
  'cards.alice.report_missing.report_type.missing': 'Perdida',
  'cards.alice.report_missing.report_type.not_arrived': 'No ha llegado',
  'cards.alice.report_missing.report_type.stolen': 'Robada',
  'cards.alice.report_missing.report_with_report_type': 'Reportar tarjeta {reportType}',
  'cards.alice.report_missing.stolen': 'La tarjeta fué robada',
  'cards.alice.report_missing.tell_us_where_to_send':
    'Por favor, indícanos dónde enviar la tarjeta de reemplazo',
  'cards.alice.report_missing.update_address_and_send_card':
    'Actualizar la dirección y enviar una nueva tarjeta',
  'cards.alice.report_missing.what_happens_next':
    'La tarjeta que termina en {last4} será cancelada y se emitirá una nueva. Tu nueva tarjeta llegará en un sobre sencillo y sin marcas en aproximadamente 3 semanas, y podrás comenzar a usarla de inmediato.',
  'cards.alice.request_an_alice_card': 'Ordena una Tarjeta Alice',
  'cards.alice.save_amount_example':
    '<strong>Ahorra</strong> alrededor de $1 dólar de tu salario neto por cada $5 dólares que gastes.',
  'cards.alice.save_on_transit_and_parking': 'Ahorra en transporte y estacionamiento al utilizar el:',
  'cards.alice.send_my_card_to': 'Enviar mi Tarjeta Alice a',
  'cards.alice.stripe-card-agreement': 'Divulgación de Firma Electrónica de Stripe',
  'cards.alice.stripe-card-user-terms': 'Términos para Usuarios Autorizados de Celtic Bank',
  'cards.alice.suggest_adding_to_digital_wallets':
    'Agrega tu Tarjeta Alice a Apple Pay o Google Pay para que comiences a utilizarla ahora mismo.',
  'cards.alice.synapse_expiring': 'Tu Tarjeta Alice se está actualizando',
  'cards.alice.synapse_expiring_description':
    'Nos hemos asociado con Stripe para mejorar la fiabilidad de tu Tarjeta Alice. <strong>Por favor, acepta los nuevos términos de servicio</strong> para que podamos emitir tu nueva tarjeta lo antes posible.',
  'cards.alice.use_card_instead_paying_from_pocket':
    'No pagues de tu bolsillo - paga los gastos de transporte al trabajo con la tarjeta Alice y accede a tus fondos pre-impuestos directamente.',
  'cards.alice.view_card_info': 'Ver Información',
  'cards.alice.what_can_i_do_with_an_alice_card': '¿Qué puede hacer la tarjeta Alice?',
  'cards.alice.your_card_is_ready': 'Tu tarjeta está lista.',
  'cards.alice.youre_getting_an_alice_card': 'Vas a recibir una Tarjeta Alice',
  'cards.cards': 'Tarjetas',
  'cards.common.card_number': 'Número de tarjeta',
  'cards.common.expiration_date': 'Fecha de expiración',
  'cards.common.verification_code': 'Código de verificación',
  'cards.omny.received_label': 'Recibido',
  'cards.omny.report_missing_label': 'Reportar como perdido',
  'cards.omny.shipping_time': 'El envío puede tardar hasta una semana.',
  'cards.omny.status.connected': 'Conectado',
  'cards.omny.status.connection_error':
    'Error de conexión - por favor, confirma tus datos de inicio de sesión',
  'cards.omny.status.pending': 'Preparando envío de la tarjeta',
  'cards.omny.status.shipped': 'Tarjeta enviada el {shippedDate}',
  'cards.personal.aggregator_service_error':
    'Actualmente estamos trabajando con nuestros socios de servicios bancarios para resolver este problema. Mientras tanto, {messageVariant}.',
  'cards.personal.aggregator_service_error_heading':
    'Algunos usuarios están experimentando problemas de conexión con sus Tarjetas Personales.',
  'cards.personal.aggregator_service_error.accounts':
    'es posible que las transacciones no aparezcan en su panel de control por un tiempo',
  'cards.personal.aggregator_service_error.transactions':
    'algunos usuarios experimentarán problemas al conectar sus cuentas bancarias',
  'cards.personal.billing_address': 'Dirección de facturación',
  'cards.personal.connection_error_retry': 'Error de conexión - por favor reinténtalo.',
  'cards.personal.debit_and_credit_accounts':
    '{bankAccountsCount} cuenta(s): {debitCount} débito, {creditCount} crédito.',
  'cards.personal.debit_only_account': '{bankAccountsCount} cuenta(s): {debitCount} débito.',
  'cards.personal.my_connected_bank_accounts': 'Tarjetas conectadas',
  'cards.personal.select_your_bank': 'Selecciona tu banco',
  'cards.personal.status.pending': 'buscando cuentas...',
  'cards.personal.redirecting_to_plaid': 'Redirigiendo a Plaid...',
  'common.address': 'dirección',
  'common.apply': 'Aplicar',
  'common.back': 'Atrás',
  'common.cancel.cancel': 'Cancelar',
  'common.cancel.never_mind': 'Cancelar',
  'common.clear_all': 'Quitar todos',
  'common.clear_filters': 'Quitar filtros',
  'common.confirm': 'Confirmar',
  'common.continue': 'Continuar',
  'common.copied': '¡Copiado!',
  'common.copy': 'Copiar',
  'common.done': 'Hecho',
  'common.edit': 'Editar',
  'common.filter': 'Filtro',
  'common.flow.please_complete_previous': 'Por favor completa los pasos anteriores',
  'common.go_back': 'Regresar',
  'common.go_back_to_label': 'Regresar a {label}',
  'common.how_it_works': 'Cómo funciona',
  'common.learn_more_about_security': 'Obtén más información sobre privacidad y seguridad',
  'common.loading': 'Cargando...',
  'common.no': 'No',
  'common.optional': 'opcional',
  'common.or': 'o',
  'common.remove': 'quitar',
  'common.save': 'Guardar',
  'common.see_more': 'Ver más',
  'common.start': 'Iniciar',
  'common.status': 'Estado',
  'common.submitting': 'Enviando...',
  'common.success': '¡Éxito!',
  'common.validation.check_for_errors': 'Por favor, revisa el formulario en busca de errores',
  'common.validation.length': 'Debe tener {length} digitos',
  'common.validation.minlength': 'Debe ser al menos {minLength} caracteres',
  'common.validation.please_enter': 'Por favor ingresa {subject}',
  'common.validation.please_pick': 'Por favor escoge {subject}',
  'common.validation.required': 'requerido',
  'common.verify': 'Verificar',
  'common.view_details': 'Ver detalles',
  'common.year': 'Año',
  'common.yes': 'Si',
  'connections.all_connections.both.byline':
    'Dos maneras de obtener un ~20% de reembolso en estacionamiento o transporte',
  'connections.all_connections.both.description': '¡Usa tus tarjetas, la Tarjeta Alice o ambas!',
  'connections.all_connections.alice_card.heading': 'Usa la Tarjeta Alice',
  'connections.all_connections.personal_cards.heading': 'Usa tus propias tarjetas',
  'connections.all_other_banks': 'Otros bancos',
  'connections.connect_through_plaid': 'conectar a través de Plaid',
  'connections.connect_another': 'Conectar otra cuenta',
  'connections.connect_more_accounts': 'Siempre puedes <connect-link>conectar más</connect-link>',
  'connections.connection_count_plural': 'Vinculaste {connectionsCount} fuentes de gastos',
  'connections.connection_count_singular': 'Vinculaste una fuente de gastos',
  'connections.disconnect.are_you_sure': '¿Está seguro de que quiere desconectar {institutionName}?',
  'connections.disconnect.label': 'Desconectar',
  'connections.error_with_connection': 'Algo salió mal con la conexión, por favor intenta nuevamente.',
  'connections.instructions.personal_card':
    'Gasta como lo haces actualmente. Alice detectará automáticamente tus gastos elegibles y te reembolsará.',
  'connections.plaid.loading.heading': 'Conectándote con Plaid. Un momento...',
  'connections.reconnect.label': 'Conectar de nuevo',
  'connections.scan_for_eligible': 'Alice solo puede buscar gastos elegibles para pre-impuestos.',
  'connections.select_from_list':
    'Selecciona una de las opciones a continuación para conectar tu cuenta de gastos.',
  'connections.we_find_we_text':
    'Cuando encontremos gastos elegibles, te enviaremos un mensaje de texto para confirmar.',
  'connections.we_work_with_providers':
    'Trabajamos con <strong>Mastercard</strong> y <strong>Plaid</strong> para proporcionar una conexión segura y unidireccional con tu cuenta.',
  'connections.will_text_when_eligible_txn':
    'Alice te enviará un mensaje de texto al <strong><pii>{tel}</pii></strong> cuando encontremos un gasto elegible.',
  'contact_support.contact.label': 'Contacta a Soporte',
  'contact_support.contact.in_app_unavailable':
    'El soporte en la aplicación no está disponible. Por favor, contacta al soporte en <strong>support@thisisalice.com</strong>.',
  'contact_support.still_have_questions': '¿Aún tienes preguntas?',
  'employee_dashboard.auto_confirmation_alert':
    '¡Alice está trabajando duro para ahorrarte tiempo y dinero extra! Encontramos gastos relacionados con tu transporte al trabajo y los confirmamos. Puedes revisar y hacer cambios si algo no está correcto.',
  'employee_dashboard.healthcheck_account.alice_card_all_requested_secondary':
    'Todo listo para que recibas reembolsos de compras elegibles desde tu Tarjeta Alice',
  'employee_dashboard.healthcheck_account.alice_card_migrate_stripe_primary':
    'Tu Tarjeta Alice está por expirar',
  'employee_dashboard.healthcheck_account.alice_card_migrate_stripe_secondary':
    'Acepta los términos actualizados para que podamos emitir una nueva tarjeta.',
  'employee_dashboard.healthcheck_account.alice_card_unrequested_primary': 'No hay Tarjeta Alice conectada',
  'employee_dashboard.healthcheck_account.alice_card_unrequested_secondary':
    '¡Solicita la Tarjeta Alice para ver ahorros más rapido!',
  'employee_dashboard.healthcheck_account.personal_complete_primary': 'Cuenta de banco conectada',
  'employee_dashboard.healthcheck_account.personal_complete_secondary':
    'Estamos detectando automaticamente tus gastos elegibles',
  'employee_dashboard.healthcheck_account.personal_error_primary': 'Error al cargar la conexión de gastos',
  'employee_dashboard.healthcheck_account.personal_error_secondary':
    'Por favor, revisa más tarde si necesitas hacer una revisión',
  'employee_dashboard.healthcheck_account.personal_none_primary': 'No hay cuentas de banco conectadas',
  'employee_dashboard.healthcheck_account.personal_none_secondary':
    'Conecta tus cuentas para que podamos detectar gastos elegibles',
  'employee_dashboard.healthcheck_account.personal_with_errors_primary':
    'Hay un problema con tu cuenta de banco conectada',
  'employee_dashboard.healthcheck_account.personal_with_errors_secondary':
    'Revisa tu conexión bancaria para actualizar los detalles',
  'employee_dashboard.healthcheck_recent_spending.all_complete_primary':
    'Los gastos elegibles están confirmados',
  'employee_dashboard.healthcheck_recent_spending.all_complete_secondary':
    '{txnsLength} transacciones confirmadas en los ultimos 90 días',
  'employee_dashboard.healthcheck_recent_spending.none_primary': 'No se encontraron gastos elegibles',
  'employee_dashboard.healthcheck_recent_spending.none_secondary':
    '¡Usa tu(s) cuenta(s) bancaria(s) conectada(s) para empezar a ahorrar!',
  'employee_dashboard.healthcheck_recent_spending.some_incomplete_primary':
    'Tienes transacciones sin confirmar',
  'employee_dashboard.healthcheck_recent_spending.some_incomplete_secondary':
    'Revisa tus gastos recientes y dinos cuales son elegibles',
  'employee_dashboard.main.accounts': 'Cuentas',
  'employee_dashboard.main.pretax_spending_savings_details':
    'El Gasto Pre-Impuestos proporcionado por <employer></employer> han añadido <savings></savings> a su salario neto desde que se inscribió inicialmente.',
  'employee_dashboard.main.recent_savings': 'Ahorros recientes',
  'employee_dashboard.main.review_eligible_spending': 'Revisa tus gastos elegibles',
  'employee_dashboard.main.savings': 'Ahorros',
  'employee_dashboard.main.welcome_back': '¡Regresaste, bienvenido  <pii>{name}</pii> 👋!',
  'enroll.enroll_using_email': 'Inscríbete por correo electrónico',
  'enroll.find_enrollment_code.heading': 'Encuentra tu formulario de inscripción',
  'enroll.find_enrollment_code.instructions':
    'Ingresa tu correo para que podamos asociarte al formulario de inscripción de tu empleador.',
  'enroll.find_enrollment_employer.employer_could_not_be_found': 'No se pudo encontrar al empleador',
  'enroll.inactive_er.contact_hr':
    'Si tienes alguna pregunta, por favor contacta a tu departamento de Recursos Humanos.',
  'enroll.inactive_er.er_no_longer_active': '{employerName} ya no ofrece beneficios a través de Alice',
  'enroll.inactive_er.if_account_then_sign_in':
    '¿Ya tienes una cuenta? Por favor, <link-login>inicia sesión</link-login> para ver tu información de inscripción.',
  'enroll.landing.alice_has_partnered_with':
    '<strong>Alice</strong> se ha asociado con <strong>{employerName}</strong> para ayudarte a aumentar tu sueldo cuando gastas en transporte al trabajo.',
  'enroll.landing.connect_your_card':
    'Conecta la tarjeta de crédito o débito que usas para pagar el estacionamiento y el transporte',
  'enroll.landing.get_ac_and_spend':
    'Obtén una Tarjeta Alice gratis, luego paga el estacionamiento y el transporte',
  'enroll.landing.get_ready_to_save':
    '¡Hasta un <strong>20% de reembolso en efectivo</strong> en transporte y estacionamiento!',
  'enroll.landing.num_employees_enrolled':
    '<strong>{employeeCount}</strong> empleados en {employerName} usan Alice.',
  'enroll.landing.then_alice_reduces_taxes':
    'Alice luego protege esos montos de los impuestos en tu cheque de pago',
  'enroll.landing.then_alice_sets_election':
    'Alice luego ajusta tu beneficio para que coincida con los montos que gastas',
  'enroll.landing.you_get_benefit':
    'Obtienes hasta un <strong>20% de reembolso en efectivo</strong> sobre lo que gastas en estacionamiento y transporte, directamente en tu cheque de pago.',
  'enroll.phone_verification.check_number':
    'Puede que necesites asegurarte de que tu número de teléfono sea correcto.',
  'enroll.phone_verification.code_sent_to_tel': 'Código enviado al <pii>{tel}</pii>',
  'enroll.phone_verification.did_not_receive_label': '¡No recibí el código!',
  'enroll.phone_verification.edit_and_resend':
    'Edítalo aquí, y haz clic en <strong>{resendLabel}</strong> cuando estés listo:',
  'enroll.phone_verification.enter_the_4_digit_code': 'Ingresa el código.',
  'enroll.phone_verification.resend_code': 'Re-enviar el código',
  'enroll.phone_verification.sending_code_to': 'Enviando el código al <pii>{tel}</pii>',
  'enroll.phone_verification.sending_verification_code': 'Enviando código de verificación',
  'enroll.phone_verification.texted_you_a_verification_code':
    'Enviamos un código de verificación al <pii><strong-nowrap>{tel}</strong-nowrap></pii>. Por favor, ingrésalo a continuación.',
  'enroll.phone_verification.verify_your_phone_number': 'Verifica tu número de teléfono',
  'enroll.register.agreements.heading': 'Acuerdos',
  'enroll.register.agreements.subheading': 'Debes aceptar <strong>ambos</strong> acuerdos antes de continuar',
  'enroll.register.already_have_account':
    'Parece que ya tienes una cuenta en Alice. Puedes <link-login>iniciar sesión</link-login> o <link-pw-reset>restablecer tu contraseña</link-pw-reset> para continuar.',
  'enroll.register.confirm_info_label': 'Confirma tu información',
  'enroll.register.contact_info.heading': 'Información de contacto',
  'enroll.register.dont_know_start_date_caption': '¿Qué pasa si no sé mi fecha de inicio?',
  'enroll.register.email_address_helper':
    'Dirección de correo electrónico donde recibes tu W2 y talones de pago',
  'enroll.register.greeting': '¡Hola! vamos a conocernos.',
  'enroll.register.i_have_not_started': 'Aún no empiezo a trabajar en {employerName}',
  'enroll.register.i_have_started_to_work_at_employer': 'Ya empecé a trabajar en {employerName}',
  'enroll.register.if_account_then_sign_in':
    '¿Ya tienes una cuenta? Por favor, <link-login>inicia sesión</link-login> para continuar.',
  'enroll.register.legal_agreement.esign_disclosure_and_consent': 'Divulgación y Consentimiento de Esign',
  'enroll.register.legal_agreement.i_have_read_and_agree':
    'He leído y estoy de acuerdo con la <esign-consent></esign-consent> y los <terms-and-conditions></terms-and-conditions>',
  'enroll.register.legal_agreement.terms_and_conditions': 'Términos y Condiciones',
  'enroll.register.personal_info.heading': 'Información personal',
  'enroll.register.phone_number_helper': 'Confirmaremos este número por mensaje de texto',
  'enroll.register.security.heading': 'Seguridad',
  'enroll.register.start_date_info.if_already_started_at_employer':
    '<strong>Si ya empezaste a trabajar en {employerName},</strong> entonces marca la casilla para indicar que ya has comenzado.',
  'enroll.register.start_date_info.if_havent_started_at_employer':
    '<strong>Si comienzas en {employerName},</strong> tendrás que confirmar la fecha de inicio con tu empleador para poder terminar el proceso de inscripción.',
  'enroll.register.start_date_info.if_i_dont_know_my_start_date': '¿Qué pasa si no se mi fecha de inicio?',
  'enroll.register.start_date_info.requirement_for_alice_to_work':
    'Alice solo puede trabajar después de que has empezado tu trabajo en {employerName}',
  'enroll.register.start_date_input':
    'Empezaré a trabajar en {employerName} el día <start-date-input></start-date-input>.',
  'enroll.register.start_date.validation.required': 'Tu fecha de inicio es requerida para poder inscribirte',
  'enroll.register.updating_your_info': 'Actualizando tu información',
  'enroll.register.validation.require_to_read_docs': 'Debes confirmar que has leído estos documentos',
  'enroll.start_enrollment.label': 'Iniciar inscripción',
  'enroll.summary.all_set': '¡Eso es! Estás listo para ahorrar en transporte al trabajo.',
  'enroll.summary.continue_label': 'Ir a página principal',
  'enroll.summary.start_spending':
    'A partir de ahora, Alice estará detectando tus gastos elegibles en transporte público y  estacionamiento.',
  'error_display.heading': 'Hemos encontrado un error.',
  'error_display.info':
    'Nuestros ingenieros han sido notificados y solucionaremos las cosas tan pronto como sea posible. Por favor, intenta de nuevo más tarde, o contacta al soporte para hacernos saber.',
  'footer.copyright': 'Copyright {copyrightYear} Pretax Hero, Inc. Todos los derechos reservados.',
  'footer.privacy_policy': 'Política de Privacidad',
  'footer.stripe_acknowledgment':
    'Pretax Hero Inc. se asocia con Stripe Payments Company para servicios de transmisión de dinero y servicios de cuenta con fondos mantenidos en Evolve Bank & Trust, Miembro de FDIC.',
  'footer.visa_acknowledgment':
    'Las tarjetas de crédito comerciales Alice Card Visa® son emitidas por Celtic Bank.',
  'home.couldnt_find_your_employment_info':
    'No pudimos encontrar tu información en nuestro sistema. Por favor, ponte en contacto para que podamos ayudarte.',
  'menu.documents': 'Documentos',
  'menu.logout': 'Salir',
  'menu.profile': 'Perfil',
  'menu.switch_to_admin': 'Cambiar a perfil de Administrador',
  'menu.switch_to_employee': 'Cambiar a perfil de Empleado',
  'onboarding.already_have_account': '¿Ya tienes una cuenta?',
  'onboarding.condition.accept_onboarding_timeline':
    'Acepto completar los pasos de incorporación dentro de 30 días para evitar las tarifas de incorporación.',
  'onboarding.offer.reference_customer_offer':
    'Me gustaría actuar como cliente de referencia y recibir un mes de servicio gratis.',
  'onboarding.offer.two_year_contract_offer':
    'Me gustaría firmar un contrato de dos años y recibir dos meses de servicio gratis.',
  'org.alice_card.kyc.complete_label': 'Completar',
  'org.alice_card.kyc.continue_label': 'Continuar',
  'org.alice_card.kyc.pending_verification_label': 'Verificar',
  'org.alice_card.kyc.start_label': 'Iniciar',
  'organization.offboard_pending.alert':
    'Tu empleador no ofrece Alice desde {offboardingDate}. Por favor comunica tus dudas sobre beneficios en gastos pre-impuestos a tu equipo de Recursos Humanos.',
  'organization.offboarded.alert':
    'Tu empleador dejará de ofrecer Alice a partir de {offboardingDate}. Por favor comunica tus dudas sobre beneficios en gastos pre-impuestos a tu equipo de Recursos Humanos.',
  'organization.offboarded.heading': 'Alice ya no está disponible en {orgName}',
  'organization.onboarding.accept_employer_tos':
    'Yo acepto los <employer-tos>Términos de Servicio del Empleador</employer-tos>',
  'organization.onboarding.company_info_heading': 'Información de la empresa',
  'organization.onboarding.excited_to_start':
    '¡Estamos emocionados por configurar tu cuenta y lanzar los Beneficios Pre-impuestos de Alice para tu equipo!',
  'organization.onboarding.first_step_account':
    'El primer paso es configurar tu acceso individual con Alice como administrador de tu organización.',
  'organization.onboarding.get_started':
    'Comienza abajo, y por favor no dudes en ponerte en contacto con nuestro equipo en admin-support@thisisalice.com si tienes algún problema.',
  'organization.onboarding.go_to_employee_dash':
    '¿Buscas tu información de inscripción? Ve a tu <employee-dashboard-link>tablero de empleado</employee-dashboard-link>.',
  'organization.onboarding.go_to_org_admin_dash':
    '¿Buscas la información de tu empresa? Ve a tu <org-admin-dashboard-link>tablero de organización</org-admin-dashboard-link>.',
  'organization.onboarding.go_to_org_or_ee_dash':
    '¿No estás configurando una nueva organización con Alice? Ve a tu <org-admin-dashboard-link>tablero de organización actual</org-admin-dashboard-link> o <employee-dashboard-link>tablero de empleado</employee-dashboard-link>.',
  'organization.onboarding.heading': '¡Bienvenido a Alice!',
  'organization.onboarding.locations.deletion_notice': 'Borrando el logotipo...',
  'organization.onboarding.locations.submission_notice': 'Actualizando logotipo...',
  'organization.onboarding.locations.upload_success_notice': '¡Logotipo guardado!',
  'organization.onboarding.login': 'Inicia sesión.',
  'organization.onboarding.order_form_heading': 'Formulario de Pedido',
  'organization.onboarding.payroll_connect_heading': 'Conectar con la nómina',
  'organization.onboarding.payroll_connect_instructions':
    'Hemos enviado por correo electrónico las instrucciones para conectar tu proveedor de nómina con Alice. Por favor sigue las instrucciones en ese correo electrónico antes de continuar.',
  'organization.onboarding.payroll_connect_instructions_completed':
    'Gracias por completar las instrucciones de conexión con la nómina. Hemos sido notificados y nos pondremos en contacto contigo directamente para finalizar tu incorporación.',
  'organization.onboarding.payroll_instructions_complete': 'Conexión con la nómina lista',
  'organization.onboarding.set_up_company':
    'Después de que tu acceso sea creado, puedes regresar para continuar configurando tu empresa, conectar Alice con la nómina, y añadir tu información de facturación y bancaria.',
  'organization.onboarding.setup_account_heading': 'Cuenta de administrador',
  'organization.onboarding.setup_account_instructions':
    'Primero, necesitamos crear una cuenta segura para <strong>ti</strong> — tu dirección de correo electrónico será tu nombre de usuario y confirmaremos tu número de teléfono por mensaje de texto.',
  'payroll.finch.authentication_completed': 'Acceso a la nómina concedido, gracias.',
  'payroll.finch.authentication_error_ocurred':
    'Hubo un error al conceder acceso a la nómina, por favor inténtelo de nuevo.',
  'payroll.finch.authentication_iframe_closed': 'No se concedió acceso a la nómina.',
  'plaid.link_session.status.created':
    'Actualmente estamos esperando la información de conexión de la tarjeta desde Plaid. Solo tomará un momento. Si hubo un problema, intenta conectarte de nuevo.',
  'plaid.link_session.status.failed':
    'Parece que hubo un problema al conectar tu tarjeta a través de Plaid. Por favor, inténtalo de nuevo.',
  'profile.address.city': 'Ciudad',
  'profile.address.note_billing_address':
    '<em>Nota:</em> esta dirección es utilizada como la dirección de facturación de tu Tarjeta Alice',
  'profile.address.optional': '(opcional)',
  'profile.address.state': 'Estado',
  'profile.address.street_address': 'Calle',
  'profile.address.zipcode': 'Código Postal',
  'profile.birthdate.label': 'Fecha de nacimiento',
  'profile.cellphone.validation.required': 'Por favor ingresa tu numero de celular',
  'profile.cellphone.validation.required_length': 'El teléfono debe tener 10 dígitos',
  'profile.email_label': 'Dirección de correo electrónico',
  'profile.email.placeholder': 'ejemplo@email.com',
  'profile.email.validation.invalid_format': 'Dirección de correo electrónico inválida',
  'profile.email.validation.required': 'La dirección de correo electrónico es requerida',
  'profile.first_name_label': 'Nombre',
  'profile.heading': 'Perfil',
  'profile.index.documents': 'Documentos',
  'profile.index.language': 'Idioma',
  'profile.index.mailing_address': 'Dirección de correo',
  'profile.index.what_can_be_managed': 'Administra tu cuenta Alice',
  'profile.last_name_label': 'Apellido',
  'profile.name.validation.no_numbers': 'El nombre no puede contener números',
  'profile.name.validation.required': 'Por favor ingresa tu nombre',
  'profile.password_confirmation_label': 'Confirmar contraseña',
  'profile.password_label': 'Contraseña',
  'profile.password.validation.required': 'la contrasena es requerida',
  'profile.preferred_name_label': 'Nombre preferido',
  'profile.tel_label': 'Número de teléfono móvil',
  'reenroll.landing_spending_connected.almost_finished': '¡Ya casi terminas!',
  'reenroll.landing_spending_connected.select_option_below_to_reenroll':
    'Por favor conecta una cuenta bancaria a continuación para comenzar tu reinscripción en Alice.',
  'reenroll.landing_spending_connected.submit_reenrollment':
    'Establece una contraseña nueva para tu cuenta <strong>{username}</strong> y envía tu reinscripción a continuación.',
  'reenroll.landing.purchases_while_unenrolled_not_eligible':
    'Las compras realizadas antes de la reinscripción no son elegibles.',
  'reenroll.landing.submit_reenrollment': 'Reinscribirme',
  'reenroll.landing.to_reenroll_password_update_required':
    'Para volver a inscribirte, ¡por favor actualiza tu contraseña!',
  'reenroll.landing.welcome_back': '¡Bienvenido de nuevo!',
  'spending.commuter_expenses.bus': 'Autobus',
  'spending.commuter_expenses.ferry': 'Ferry',
  'spending.commuter_expenses.light_rail': 'Tren ligero',
  'spending.commuter_expenses.parking_meter': 'Parquímetro',
  'spending.commuter_expenses.rideshare': 'Viaje compartido',
  'spending.commuter_expenses.streetcar': 'Tranvía',
  'spending.commuter_expenses.subway': 'Metro',
  'spending.commuter_expenses.train': 'Tren',
  'spending.eligible_expenses.eligible_expenses_and_the_irs':
    'Los gastos de transporte al trabajo elegibles son definidos por el IRS. Incluye el uso de cualquiera de los siguientes medios para ir o volver del trabajo:',
  'spending.eligible_expenses.whats_is_an_eligible_expense': 'Que son gastos elegibles',
  'spending.heading': 'Gastos',
  'spending.missing_transaction.brief_description': 'El nombre del comercio',
  'spending.missing_transaction.constraint_on_mass_transit':
    'Los reclamos de transporte público sólo pueden ser realizados con transacciones de tarjetas conectadas',
  'spending.missing_transaction.date_error':
    'Los bancos pueden tardar hasta una semana en informarnos sobre tus compras elegibles. Si no ves un mensaje de texto nuestro después de ese tiempo, ¡háznolo saber!',
  'spending.missing_transaction.expense_description':
    'La compra se hizo en <description-input></description-input>.',
  'spending.missing_transaction.expense_details':
    'El <date-input></date-input> pagué <amount-input></amount-input> por un gasto de <pretax-category-input></pretax-category-input>',
  'spending.missing_transaction.i_paid_with': 'Pagué con',
  'spending.missing_transaction.instructions_to_submit':
    'Si no te enviamos un mensaje de texto sobre una transacción de hace una semana y aún no la ves en tu tablero, danos la información y la añadiremos.',
  'spending.missing_transaction.issued_by': 'emitido por {bankName}',
  'spending.missing_transaction.my_connected_card': 'una tarjeta conectada a Alice',
  'spending.missing_transaction.payment_card.alice_card': 'Tarjeta Alice',
  'spending.missing_transaction.personal_card': 'Tarjeta no conectada a Alice',
  'spending.missing_transaction.submit_expense': 'Enviar gasto',
  'spending.missing_transaction.submitting_expense': 'Enviando gasto...',
  'spending.receipt.attach_receipt': 'Anexar recibo',
  'spending.receipt.context_around_receipt':
    'Para que te reembolsemos este gasto, debes presentar una copia de tu recibo. Utilizaremos el recibo para confirmar que es elegible y procesar el reembolso.',
  'spending.receipt.image_instructions': 'Por favor sube una imagen de tu recibo',
  'spending.receipt.receipt': 'Recibo',
  'spending.receipt.receipt_attachment_compatibility': 'JPG, PNG, or PDF. Tamaño límite: 10MB',
  'spending.receipt.take_a_picture': 'Toma una foto',
  'spending.spending': 'Gastos',
  'spending.summary.category.over_limit':
    'Obtengo ahorros pre-impuestos hasta por <strong>{limit}</strong> en transporte público cada mes. Hasta ahora en <strong>{monthName}</strong>, he confirmado ahorros por <strong>{spentSoFar}</strong>. Alice procesará el exceso en el siguiente mes, hasta los <strong>{limit}</strong> de límite.',
  'spending.summary.category.under_limit':
    'Recibiré ahorros pre-impuestos hasta por <strong>{limit}</strong> de {pretaxCategory} cada mes. Hasta ahora en <strong>{monthName}</strong>, he confirmado ahorros por <strong>{spentSoFar}</strong> y tengo <strong>{available}</strong> disponibles para confirmar este mes. Los gastos extras que confirme se aplicarán al total del siguiente mes.',
  'transactions.a_category': 'Una categoría',
  'transactions.amount': 'Monto',
  'transactions.category': 'Categoría',
  'transactions.date': 'Fecha',
  'transactions.empty_transaction_list.benefits_started':
    'Tus beneficios comenzaron el {benefitsStartDate} - cuando encontremos gastos elegibles después de esa fecha, se mostrarán aquí.',
  'transactions.empty_transaction_list.no_card_connected':
    'No tienes ninguna tarjeta conectada. Para ver las transacciones elegibles, conecta una tarjeta bancaria o solicita una Tarjeta Alice para utilizarla.',
  'transactions.empty_transaction_list.no_eligible_transactions':
    'Todavía no hemos visto ningún gasto elegible de tu(s) tarjeta(s) conectada(s) en el rango de fechas seleccionadas. Por favor, envíanos la transacción faltante si has hecho compras elegibles.',
  'transactions.empty_transaction_list.no_transactions_and_no_cards':
    'No tienes ninguna tarjeta conectada. Para ver las transacciones elegibles, conecta una tarjeta bancaria y empieza a gastar.',
  'transactions.empty_transaction_list.no_transactions_but_has_alice_card':
    'No tienes ninguna transacción que ver. Por favor, usa tu Tarjeta Alice o conecta una tarjeta bancaria y empieza a gastar.',
  'transactions.empty_transaction_list.no_transactions_but_has_cards':
    'No tienes ninguna transacción que revisar. Por favor, asegúrate de pagar los gastos de tránsito elegibles con tu(s) tarjeta(s) conectada(s).',
  'transactions.empty_transaction_list.no_transactions_but_has_gated_alice_card':
    'No tienes ninguna transacción para ver. Te enviaremos un mensaje de texto cuando tu Tarjeta Alice esté lista para usar, pero también puedes conectar una tarjeta bancaria y comenzar a gastar.',
  'transactions.empty_transaction_list.no_transactions_but_personal_card':
    'No tienes ninguna transacción que revisar. Por favor, asegúrate de pagar los gastos de tránsito elegibles con tu(s) tarjeta(s) conectada(s), o solicita una Tarjeta Alice para utilizarla.',
  'transactions.empty_transaction_list.no_transactions_but_personal_card_only':
    'No tienes ninguna transacción que revisar. Por favor, asegúrate de que pagas los gastos de tránsito elegibles con tu(s) tarjeta(s) conectada(s).',
  'transactions.empty_transaction_list.no_transactions_for_filter':
    'No hay transacciones que coincidan con los filtros seleccionados. Por favor, actualiza la configuración de los filtros.',
  'transactions.matching_transactions': 'transacciones encontratads',
  'transactions.payment_type.alice_card': 'Tarjeta Alice',
  'transactions.payment_type.cash': 'Efectivo',
  'transactions.payment_type.personal_card': 'Tarjeta personal',
  'transactions.pretax_categories.dental': 'Cuidado dental',
  'transactions.pretax_categories.dependent_care': 'Cuidado de dependientes',
  'transactions.pretax_categories.healthcare': 'Salud',
  'transactions.pretax_categories.mass_transit': 'Transporte Público',
  'transactions.pretax_categories.not_eligible': 'No elegible',
  'transactions.pretax_categories.parking': 'Estacionamiento',
  'transactions.pretax_categories.vision': 'Cuidado de la vista',
  'transactions.spending.auto_confirmed': 'Alice automaticamente confirmó esto para ti en {autoConfirmedOn}.',
  'transactions.spending.auto_confirmed_disconfirm':
    'Puedes marcar esto como <disconfirm-action>"No - esto no fué un gasto de transporte al trabajo"</disconfirm-action> si lo clasificamos incorrectamente.',
  'transactions.spending.disconfirm.success': 'Transacción indicada como inelegible',
  'transactions.spending.expense_with_status': 'Gasto {status}',
  'transactions.spending.transaction_record_details':
    'El {date}, gastaste <amount></amount>  en un gasto elegible {category}.',
  'transactions.spending.you_paid_with': 'Pagaste con  <payment_type></payment_type>.',
  'transactions.status.approved.label': 'Gasto aprobado para ser reembolsado',
  'transactions.status.approved.summary': 'Tu gasto ha sido aprobado para ser reembolsado.',
  'transactions.status.approved.title': 'Aprobado',
  'transactions.status.confirmed.label': 'Gasto confirmado para reembolso',
  'transactions.status.confirmed.summary': 'Tu gasto ha sido confirmado para reembolso',
  'transactions.status.confirmed.title': 'Confirmado',
  'transactions.status.deducted.label': 'Gasto deducido de tu cheque de pago',
  'transactions.status.deducted.summary': 'Tu gasto ha sido deducido de tu cheque de pago',
  'transactions.status.deducted.title': 'Deducido',
  'transactions.status.deduction_canceled.label': 'La deducción de tu cheque del pago ha sido cancelada',
  'transactions.status.deduction_canceled.summary': 'Tu gasto fue deducido de tu cheque de pago',
  'transactions.status.deduction_canceled.title': 'Deducción cancelada',
  'transactions.status.denied.label': 'Solicitud de gastos denegada',
  'transactions.status.denied.summary': 'Alice determinó que el gasto presentado no es un gasto elegible',
  'transactions.status.denied.title': 'Denegado',
  'transactions.status.disconfirmed.label': 'Gasto confirmado, luego marcado como ineligible.',
  'transactions.status.disconfirmed.summary':
    'Esta transacción fue confirmada, luego marcada como ineligible. Si ya fuiste reembolsado por este gasto, la cantidad será deducida de un futuro reembolso.',
  'transactions.status.disconfirmed.title': 'desconfirmada',
  'transactions.status.reimbursed_deduction_pending.label': 'Gasto pagado',
  'transactions.status.reimbursed_deduction_pending.summary':
    'Tu gasto será deducido de tu próximo cheque de pago',
  'transactions.status.reimbursed_deduction_pending.title': 'Pendiente',
  'transactions.status.reimbursed.label': 'Reembolsado',
  'transactions.status.reimbursed.summary': 'Tu gasto fue reembolsado en tu cheque del {dateRange}',
  'transactions.status.reimbursed.title': 'Reembolsado',
  'transactions.status.rejected.label': 'Gasto marcado como no elegible',
  'transactions.status.rejected.summary': 'Indicaste que esta transacción no era para un gasto elegible',
  'transactions.status.rejected.title': 'desconfirmada',
  'transactions.status.submitted.label': 'Gasto enviado para aprobación',
  'transactions.status.submitted.summary': 'Tu gasto ha sido enviado y está esperando aprobación',
  'transactions.status.submitted.title': 'Enviado',
  'transactions.status.unconfirmed.label': 'Tu gasto aún no ha sido confirmado',
  'transactions.status.unconfirmed.prompt': 'Este gasto fue para {pretaxCategory}?',
  'transactions.status.unconfirmed.summary':
    'Tu gasto no está confirmado aún- confirmarlo desde la lista de transacciones para recibir un reembolso.',
  'transactions.status.unconfirmed.title': 'Aún no esta confirmado',
  'transactions.transaction': 'Transacción',
  'transactions.transaction_list_item.auto_confirmed': 'Auto-confirmado - Ver Detalles',
  'transactions.transaction_list_item.estimated_savings.abbr': 'Ahorro estimado',
  'transactions.transaction_list_item.status': 'Estado: {status}',
  'transactions.transaction_list.are_these_for': 'Estas transacciones pertenecen a',
  'transactions.transaction_list.did_we_missing_something': '¿Nos perdimos de algo?',
  'transactions.transaction_list.eligible_commuter_expenses': 'gastos de transporte al trabajo elegibles?',
  'transactions.transaction_list.is_it_for': 'Esta transacción pertenece a',
  'transactions.transaction_list.submit_missing_transaction': 'Enviar la transacción faltante',
  'transactions.transactions': 'Transacciones',
}
