import * as Types from '../../../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EphemeralKeySecretQueryVariables = Types.Exact<{
  cardId: Types.Scalars['Int']['input']
  nonce: Types.Scalars['String']['input']
}>

export type EphemeralKeySecretQuery = {
  __typename?: 'Query'
  employee?: {
    __typename?: 'Employee'
    spendingConnectionInfo: {
      __typename?: 'SpendingConnectionInfo'
      aliceCardAccounts?: Array<{
        __typename?: 'AliceCardAccount'
        id: number
        aliceCard?: { __typename?: 'AliceCard'; id: number; ephemeralKeySecret?: string | null } | null
      }> | null
    }
  } | null
}

export const EphemeralKeySecretDocument = `
    query EphemeralKeySecret($cardId: Int!, $nonce: String!) {
  employee {
    spendingConnectionInfo {
      aliceCardAccounts {
        id
        aliceCard(id: $cardId) {
          id
          ephemeralKeySecret(nonce: $nonce)
        }
      }
    }
  }
}
    `

export const useEphemeralKeySecretQuery = <TData = EphemeralKeySecretQuery, TError = Error>(
  variables: EphemeralKeySecretQueryVariables,
  options?: UseQueryOptions<EphemeralKeySecretQuery, TError, TData>
) => {
  return useQuery<EphemeralKeySecretQuery, TError, TData>(
    ['EphemeralKeySecret', variables],
    fetchGql<EphemeralKeySecretQuery, EphemeralKeySecretQueryVariables>(
      EphemeralKeySecretDocument,
      variables
    ),
    options
  )
}

useEphemeralKeySecretQuery.getKey = (variables: EphemeralKeySecretQueryVariables) => [
  'EphemeralKeySecret',
  variables,
]
