export const ENGLISH_TRNS = {
  'admin.enrollment.heading': 'Benefits enrollment',
  'auth.forgot_password': 'Forgot your password?',
  'auth.forgot_password.instructions':
    'No problem. Just enter your email address below. We will send you a link so you can reset your password.',
  'auth.forgot_password.missing_reset_email':
    "Didn't get your reset email? Be sure you entered the same email address you enrolled with!",
  'auth.forgot_password.notification.requested_password':
    'Request submitted - check your email for further instructions.',
  'auth.forgot_password.notification.requesting_password': 'Requesting password reset email...',
  'auth.login.code_sent':
    "We sent a code sent to <pii><strong-nowrap>{tel}</strong-nowrap></pii>. Didn't receive your code?",
  'auth.login.do_login': 'Log in',
  'auth.login.get_code': 'Get a sign-in code',
  'auth.login.get_new_code': 'Get a new one',
  'auth.login.logging_in': 'Logging in...',
  'auth.login.phone_verification': 'Or, sign in with your phone',
  'auth.login.requesting_code': 'Requesting a code',
  'auth.login.sign_in': 'Sign in',
  'auth.login.verification_code': '6-digit verification code',
  'auth.login.verifying_code': 'Verifying your code',
  'auth.reset_password.heading': 'Reset your password',
  'auth.reset_password.new_password_label': 'New password',
  'auth.reset_password.request_reset': 'Reset password',
  'auth.reset_password.resetting_password': 'Resetting password...',
  'auth.reset_password.success': 'Password reset successfully - please log in to continue',
  'auth.reset_password.validation.no_match': 'Passwords must match',
  'auth.set_new_password.input_placeholder': 'Enter your new password here',
  'cards.alice.a_card_is_ready': 'Your Alice card ending in <strong>{last4}</strong> is ready to use',
  'cards.alice.about_card_arrival':
    'Your Alice Card will arrive within 3 weeks. You can start using your instant Alice Card after you finish enrolling.',
  'cards.alice.apple-pay-terms': 'Apple Pay Terms (Celtic Bank)',
  'cards.alice.arrival_date': 'Your physical card should arrive by <strong>{arrivalDate}</strong>.',
  'cards.alice.balance_gated_account_info':
    "We have all the info we need to issue your new card. We will notify you by text or email when it's ready!",
  'cards.alice.card_heading': 'Alice Card (<strong>{cardType}</strong>)',
  'cards.alice.card_initial_state':
    'Your Alice Card comes ready to use. No need to preload. No receipts required!',
  'cards.alice.confirm_you_read_the_documents': 'You must confirm that you have read these documents',
  'cards.alice.digital_wallet.add_instant_card_to_digital_wallet':
    'Add your instant card to your digital wallet',
  'cards.alice.digital_wallet.add_to_apple_pay_instructions':
    '<li>Go to Wallet and tap the add (+) button.</li>\n     <li>Choose "Credit or Debit Card"</li>\n     <li>Copy your instant Alice Card number or scan your physical Alice Card details (You can also choose to "Enter Card Details Manually")</li>\n     <li>Confirm your CVV</li>\n     <li>Agree to Terms & conditions</li>',
  'cards.alice.digital_wallet.add_to_google_pay_instructions':
    '<li>Open Google Pay</li>\n     <li>Tap on your Profile Picture</li>\n     <li>Tap on "Bank Accounts and Cards"</li>\n     <li>Tap on "Add card".</li>\n     <li> Enter the Alice Card number, expiry date, CVV, and your name and billing address</li>\n     <li>Accept issuer Terms & conditions</li>',
  'cards.alice.digital_wallet.add_your_alice_card_to_platform': 'Add your Alice Card to {platform}',
  'cards.alice.digital_wallet.opening_instruction':
    'To add your Alice Card to Apple Pay or Google Pay, just copy the Instant card details and paste them into Apple Pay or Android Pay.',
  'cards.alice.get_new_card': 'Request a new card',
  'cards.alice.how_do_i_use_my_alice_card': 'How do I use my Alice Card?',
  'cards.alice.i_have_read_agreements':
    'I have read and agree to the <stripe-card-agreement></stripe-card-agreement> and the <stripe-card-user-terms></stripe-card-user-terms> and the <apple-pay-terms></apple-pay-terms>.',
  'cards.alice.instant.ready':
    'Your Instant Alice Card is available to use online and add to your mobile wallet.',
  'cards.alice.instant.show_details': 'Show details',
  'cards.alice.list.heading': 'My Alice Cards',
  'cards.alice.list.summary':
    'With Alice Card, you can tap or swipe for commuting to access your pretax funds directly.',
  'cards.alice.loading': 'Looking up your Alice Card account info...',
  'cards.alice.order_instant_card':
    'Would you like an instant card that you can use online or from your digital wallet?',
  'cards.alice.order_instant_card_description': "Check your details and we'll issue it immediately.",
  'cards.alice.order_my_alice_card': 'Order my Alice Card',
  'cards.alice.order_physical_card': 'Would you like a physical card that you can use anywhere?',
  'cards.alice.order_physical_card_description':
    "Check your details and we'll send one to you. It should arrive within 3 weeks.",
  'cards.alice.ordering_your_alice_card':
    "Confirm your details and we'll issue an instant card that you can use as soon as it is funded by your employer. When it's ready, you can use it online or from your digital wallet. We will also send you a physical card that you can use anywhere - it should arrive within 3 weeks of your request.",
  'cards.alice.paying_using_alice_card':
    '<strong>Pay</strong> using your Alice Card when you spend on the eligible expenses described above.  You can swipe, tap, or add your Alice Card to your Apple/Google wallet.',
  'cards.alice.relax_with_alice':
    '<strong>Relax</strong> while Alice does its magic & texts you a savings confirmation.',
  'cards.alice.report_missing_label': 'Report missing',
  'cards.alice.report_missing.card_was_mailed_to': 'Your card was mailed to',
  'cards.alice.report_missing.card_will_be_mailed_to': 'Your card will be mailed to',
  'cards.alice.report_missing.change_address_label': 'I want to update my address',
  'cards.alice.report_missing.heading': 'Report missing Alice Card',
  'cards.alice.report_missing.missing': 'My card is missing',
  'cards.alice.report_missing.never_arrived': 'My card never arrived',
  'cards.alice.report_missing.report_type.missing': 'missing',
  'cards.alice.report_missing.report_type.not_arrived': 'not arrived',
  'cards.alice.report_missing.report_type.stolen': 'stolen',
  'cards.alice.report_missing.report_with_report_type': 'Report card {reportType}',
  'cards.alice.report_missing.stolen': 'My card was stolen',
  'cards.alice.report_missing.tell_us_where_to_send': 'Please let us know where to send the replacement card',
  'cards.alice.report_missing.update_address_and_send_card': 'Update address and send a new card',
  'cards.alice.report_missing.what_happens_next':
    'The card ending in {last4} will be canceled and a new one will be issued. Your new card will arrive in a plain, unmarked envelope in about 3 weeks, and you can start using it right away.',
  'cards.alice.request_an_alice_card': 'Order an Alice Card',
  'cards.alice.save_amount_example':
    '<strong>Save</strong> about $1 of your take home pay for every $5 you spend.',
  'cards.alice.save_on_transit_and_parking': 'Save on transit and parking when using the:',
  'cards.alice.send_my_card_to': 'Send my card to',
  'cards.alice.stripe-card-agreement': 'Stripe E-Sign Disclosure',
  'cards.alice.stripe-card-user-terms': 'Celtic Bank Authorized User Terms',
  'cards.alice.suggest_adding_to_digital_wallets':
    'Add your Alice Card to Apple Pay or Google Pay to start using it instantly.',
  'cards.alice.synapse_expiring': 'Your Alice Card is being upgraded',
  'cards.alice.synapse_expiring_description':
    'We have partnered with Stripe to improve the reliability of your Alice Card. <strong>Please accept the new terms of service</strong> so that we can issue your new card as soon as possible.',
  'cards.alice.use_card_instead_paying_from_pocket':
    "Don't pay out of pocket - tap or swipe for commuting expenses to access your pretax funds directly.",
  'cards.alice.view_card_info': 'View Card Info',
  'cards.alice.what_can_i_do_with_an_alice_card': 'What can Alice Card do?',
  'cards.alice.your_card_is_ready': 'Your card is ready',
  'cards.alice.youre_getting_an_alice_card': "You're getting an Alice Card",
  'cards.cards': 'Cards',
  'cards.common.card_number': 'Card number',
  'cards.common.expiration_date': 'Expiration date',
  'cards.common.verification_code': 'Verification code',
  'cards.omny.received_label': 'Received',
  'cards.omny.report_missing_label': 'Report missing',
  'cards.omny.shipping_time': 'Shipping may take up to a week.',
  'cards.omny.status.connected': 'Connected',
  'cards.omny.status.connection_error': 'Connection error - please confirm your login details',
  'cards.omny.status.pending': 'Preparing card shipment',
  'cards.omny.status.shipped': 'Card sent on {shippedDate}',
  'cards.personal.aggregator_service_error':
    'We are currently working with our banking services partners to resolve this issue. In the meantime, {messageVariant}.',
  'cards.personal.aggregator_service_error_heading':
    'Some users are experiencing card connectivity issues with Personal Cards',
  'cards.personal.aggregator_service_error.accounts':
    'some users may experience issues connecting their bank accounts',
  'cards.personal.aggregator_service_error.transactions': 'some transactions may take a while to appear',
  'cards.personal.billing_address': 'Billing address',
  'cards.personal.connection_error_retry': 'Connection error - please reconnect.',
  'cards.personal.debit_and_credit_accounts':
    '{bankAccountsCount} account(s): {debitCount} debit, {creditCount} credit.',
  'cards.personal.debit_only_account': '{bankAccountsCount} account(s): {debitCount} debit.',
  'cards.personal.my_connected_bank_accounts': 'Connected cards',
  'cards.personal.select_your_bank': 'Select your bank',
  'cards.personal.status.pending': 'looking up accounts...',
  'cards.personal.redirecting_to_plaid': 'Redirecting to Plaid...',
  'common.address': 'address',
  'common.apply': 'Apply',
  'common.back': 'Back',
  'common.cancel.cancel': 'Cancel',
  'common.cancel.never_mind': 'Never mind',
  'common.clear_all': 'Clear all',
  'common.clear_filters': 'Clear filters',
  'common.confirm': 'Confirm',
  'common.continue': 'Continue',
  'common.copied': 'Copied!',
  'common.copy': 'Copy',
  'common.done': 'Done',
  'common.edit': 'Edit',
  'common.filter': 'Filter',
  'common.flow.please_complete_previous': 'Please complete previous step(s)',
  'common.go_back': 'Go back',
  'common.go_back_to_label': 'Go back to {label}',
  'common.how_it_works': 'How it works',
  'common.learn_more_about_security': 'Learn more about privacy & security',
  'common.loading': 'Loading...',
  'common.no': 'No',
  'common.optional': 'optional',
  'common.or': 'or',
  'common.remove': 'remove',
  'common.save': 'Save',
  'common.see_more': 'See more',
  'common.start': 'Start',
  'common.status': 'Status',
  'common.submitting': 'Submitting...',
  'common.success': 'Success!',
  'common.validation.check_for_errors': 'Please check the form for errors',
  'common.validation.length': 'Must have {length} digits',
  'common.validation.minlength': 'Must be at least {minLength} characters',
  'common.validation.please_enter': 'please enter {subject}',
  'common.validation.please_pick': 'please pick {subject}',
  'common.validation.required': 'required',
  'common.verify': 'Verify',
  'common.view_details': 'View details',
  'common.year': 'Year',
  'common.yes': 'Yes',
  'connections.all_connections.both.byline': 'Two ways to get ~20% cash back on parking or transit',
  'connections.all_connections.both.description': 'Use your cards, Alice Card, or both!',
  'connections.all_connections.alice_card.heading': 'Use Alice Card',
  'connections.all_connections.personal_cards.heading': 'Use your own cards',
  'connections.all_other_banks': 'All other banks',
  'connections.connect_through_plaid': 'connect through Plaid',
  'connections.connect_another': 'Connect another account',
  'connections.connect_more_accounts': 'You can always <connect-link>connect more</connect-link>',
  'connections.connection_count_plural': '{connectionsCount} linked spending sources',
  'connections.connection_count_singular': 'One linked spending source',
  'connections.disconnect.are_you_sure': 'Are you sure you want to disconnect {institutionName}?',
  'connections.disconnect.label': 'Disconnect',
  'connections.error_with_connection': 'Something went wrong with the connection, please try again.',
  'connections.instructions.personal_card':
    'Spend as you already do. Alice will detect your eligible spending and reimburse you.',
  'connections.plaid.loading.heading': 'Connecting you to Plaid. One moment...',
  'connections.reconnect.label': 'Reconnect',
  'connections.scan_for_eligible': 'Alice can only scan for pretax eligible spending.',
  'connections.select_from_list': 'Select one of the options below to connect your spending account.',
  'connections.we_find_we_text': 'When we find eligible spending we will send you a text to confirm.',
  'connections.we_work_with_providers':
    'We work with <strong>Mastercard</strong> and <strong>Plaid</strong> to provide a secure, one-way connection to your account.',
  'connections.will_text_when_eligible_txn':
    'Alice will text at <strong><pii>{tel}</pii></strong> when we find eligible spending',
  'contact_support.contact.label': 'Contact support',
  'contact_support.contact.in_app_unavailable':
    'In-app support is not available. Please contact support at <strong>support@thisisalice.com</strong>.',
  'contact_support.still_have_questions': 'Still have questions?',
  'employee_dashboard.auto_confirmation_alert':
    'Alice is working hard to save you extra time and money! We found expenses related to your work commute and confirmed them. You can review and make changes if we got something wrong.',
  'employee_dashboard.healthcheck_account.alice_card_all_requested_secondary':
    "You're all set for instant reimbursements from eligible purchases on your Alice Card",
  'employee_dashboard.healthcheck_account.alice_card_migrate_stripe_primary':
    'Your Alice Card is expiring soon',
  'employee_dashboard.healthcheck_account.alice_card_migrate_stripe_secondary':
    'Accept updated terms so we can issue a new card.',
  'employee_dashboard.healthcheck_account.alice_card_unrequested_primary': 'No Alice Card connected',
  'employee_dashboard.healthcheck_account.alice_card_unrequested_secondary':
    'Request an Alice Card to see savings sooner!',
  'employee_dashboard.healthcheck_account.personal_complete_primary': 'Bank account connected',
  'employee_dashboard.healthcheck_account.personal_complete_secondary':
    "We're automatically watching for eligible spending",
  'employee_dashboard.healthcheck_account.personal_error_primary': 'Error loading spending connection',
  'employee_dashboard.healthcheck_account.personal_error_secondary':
    'Please check back later if you need to review',
  'employee_dashboard.healthcheck_account.personal_none_primary': 'No bank accounts connected',
  'employee_dashboard.healthcheck_account.personal_none_secondary':
    'Connect your accounts so we can detect eligible spending',
  'employee_dashboard.healthcheck_account.personal_with_errors_primary':
    'Problem with connected bank account',
  'employee_dashboard.healthcheck_account.personal_with_errors_secondary':
    'Review your bank connection to update details',
  'employee_dashboard.healthcheck_recent_spending.all_complete_primary': 'Eligible spending is confirmed',
  'employee_dashboard.healthcheck_recent_spending.all_complete_secondary':
    '{txnsLength} confirmed transactions in the last 90 days',
  'employee_dashboard.healthcheck_recent_spending.none_primary': 'No eligible spending detected',
  'employee_dashboard.healthcheck_recent_spending.none_secondary':
    'Use your connected bank account(s) to start saving!',
  'employee_dashboard.healthcheck_recent_spending.some_incomplete_primary':
    'You have unconfirmed transactions',
  'employee_dashboard.healthcheck_recent_spending.some_incomplete_secondary':
    'Review recent spending to let us know what is eligible',
  'employee_dashboard.main.accounts': 'Accounts',
  'employee_dashboard.main.pretax_spending_savings_details':
    'Pretax Spending provided by <employer></employer> has added <savings></savings> to your take-home pay since you first enrolled.',
  'employee_dashboard.main.recent_savings': 'Recent savings',
  'employee_dashboard.main.review_eligible_spending': 'Review your eligible spending',
  'employee_dashboard.main.savings': 'Savings',
  'employee_dashboard.main.welcome_back': 'Welcome back <pii>{name}</pii> 👋',
  'enroll.enroll_using_email': 'Enroll using email',
  'enroll.find_enrollment_code.heading': 'Find your enrollment form',
  'enroll.find_enrollment_code.instructions':
    "Enter your email address so that we can match you to your employer's enrollment form.",
  'enroll.find_enrollment_employer.employer_could_not_be_found': 'Employer could not be found',
  'enroll.inactive_er.contact_hr': 'If you have any questions, please contact your HR department.',
  'enroll.inactive_er.er_no_longer_active': '{employerName} is no longer offering benefits through Alice',
  'enroll.inactive_er.if_account_then_sign_in':
    'Already have an account? Please <link-login>sign in</link-login> to see your enrollment information.',
  'enroll.landing.alice_has_partnered_with':
    '<strong>Alice</strong> is an employee benefit offered by <strong>{employerName}</strong> at no cost to you.',
  'enroll.landing.connect_your_card':
    'Connect the credit or debit card you use to pay for parking and transit',
  'enroll.landing.get_ac_and_spend': 'Get a free Alice Card, then pay for parking and transit',
  'enroll.landing.get_ready_to_save': 'Up to <strong>20% cash back</strong> on transit and parking!',
  'enroll.landing.num_employees_enrolled':
    '<strong>{employeeCount}</strong> people at {employerName} are already using Alice.',
  'enroll.landing.then_alice_reduces_taxes': 'Alice then protects those amounts from taxes on your paycheck',
  'enroll.landing.then_alice_sets_election': 'Alice then sets your benefit to match the amounts you spend',
  'enroll.landing.you_get_benefit':
    'You get up to <strong>20% cash back</strong> on what you spend on parking and transit, right in your paycheck.',
  'enroll.phone_verification.check_number': 'You may need to make sure your phone number is correct.',
  'enroll.phone_verification.code_sent_to_tel': 'Code sent to <pii>{tel}</pii>',
  'enroll.phone_verification.did_not_receive_label': "I didn't receive a code!",
  'enroll.phone_verification.edit_and_resend':
    "Edit it here, and click <strong>{resendLabel}</strong> when you're ready:",
  'enroll.phone_verification.enter_the_4_digit_code': 'Enter the 4 digit code',
  'enroll.phone_verification.resend_code': 'Resend code',
  'enroll.phone_verification.sending_code_to': 'Sending code to <pii>{tel}</pii>',
  'enroll.phone_verification.sending_verification_code': 'Sending verification code',
  'enroll.phone_verification.texted_you_a_verification_code':
    'We texted a confirmation code to <pii><strong-nowrap>{tel}</strong-nowrap></pii>. Please enter it below.',
  'enroll.phone_verification.verify_your_phone_number': 'Verify your phone number',
  'enroll.register.agreements.heading': 'Agreements',
  'enroll.register.agreements.subheading':
    'You must accept <strong>both</strong> agreements before continuing',
  'enroll.register.already_have_account':
    'It looks like you already have an account at Alice. You can <link-login>sign in</link-login> or request a <link-pw-reset>password reset</link-pw-reset> to continue.',
  'enroll.register.confirm_info_label': 'Confirm your info',
  'enroll.register.contact_info.heading': 'Contact info',
  'enroll.register.dont_know_start_date_caption': "What if I don't know my start date?",
  'enroll.register.email_address_helper': 'Email address where you receive your W2 and paystubs',
  'enroll.register.greeting': "Hi there! Let's get you registered.",
  'enroll.register.i_have_not_started': 'I have not started work at {employerName}',
  'enroll.register.i_have_started_to_work_at_employer': 'I have started work at {employerName}',
  'enroll.register.if_account_then_sign_in':
    'Already have an account? Please <link-login>sign in</link-login> to continue.',
  'enroll.register.legal_agreement.esign_disclosure_and_consent': 'Esign Disclosure and Consent',
  'enroll.register.legal_agreement.i_have_read_and_agree':
    'I have read and agree to the <esign-consent></esign-consent> and <terms-and-conditions></terms-and-conditions>',
  'enroll.register.legal_agreement.terms_and_conditions': 'Terms and Conditions',
  'enroll.register.personal_info.heading': 'Personal info',
  'enroll.register.phone_number_helper': "We'll confirm this number by text",
  'enroll.register.security.heading': 'Security',
  'enroll.register.start_date_info.if_already_started_at_employer':
    "<strong>If you already started your job at {employerName},</strong> then check the box to indicate that you've already started.",
  'enroll.register.start_date_info.if_havent_started_at_employer':
    '<strong>If you start at {employerName} in the future,</strong> you will need to check with your employer to get the correct date in order to complete enrollment.',
  'enroll.register.start_date_info.if_i_dont_know_my_start_date': "What if I don't know my start date?",
  'enroll.register.start_date_info.requirement_for_alice_to_work':
    "Alice can only work once you've started your job at {employerName}",
  'enroll.register.start_date_input':
    'I will start work at {employerName} on <start-date-input></start-date-input>.',
  'enroll.register.start_date.validation.required': 'Start date is required in order to enroll',
  'enroll.register.updating_your_info': 'Updating your info',
  'enroll.register.validation.require_to_read_docs': 'You must confirm that you have read these documents',
  'enroll.start_enrollment.label': 'Start enrollment',
  'enroll.summary.all_set': "That's it! You're all set for Pretax Spending on commuting.",
  'enroll.summary.continue_label': 'Continue to dashboard',
  'enroll.summary.start_spending':
    'Starting now, Alice is looking for your eligible mass transit and parking spending.',
  'error_display.heading': "We've encountered an error.",
  'error_display.info':
    'Our engineers have been notified and we will get things sorted out as soon as possible. Please try again later, or contact support to let us know.',
  'footer.copyright': 'Copyright {copyrightYear} Pretax Hero, Inc. All rights reserved.',
  'footer.privacy_policy': 'Privacy Policy',
  'footer.stripe_acknowledgment':
    'Pretax Hero Inc. partners with Stripe Payments Company for money transmission services and account services with funds held at Evolve Bank & Trust, Member FDIC.',
  'footer.visa_acknowledgment': 'Alice Card Visa® Commercial Credit cards are issued by Celtic Bank.',
  'home.couldnt_find_your_employment_info':
    "We couldn't find your info in our system. Please get in touch so we can help.",
  'menu.documents': 'Documents',
  'menu.logout': 'Logout',
  'menu.profile': 'Profile',
  'menu.switch_to_admin': 'Switch to admin account',
  'menu.switch_to_employee': 'Switch to employee account',
  'onboarding.already_have_account': 'Already have an account?',
  'onboarding.condition.accept_onboarding_timeline':
    'I agree to complete the onboarding steps within 30 days to avoid onboarding fees.',
  'onboarding.offer.reference_customer_offer':
    'I would like to act as a reference customer, and receive one month of service for free.',
  'onboarding.offer.two_year_contract_offer':
    'I would like to sign a two-year contract, and receive two months of service for free.',
  'org.alice_card.kyc.complete_label': 'Complete',
  'org.alice_card.kyc.continue_label': 'Continue',
  'org.alice_card.kyc.pending_verification_label': 'Check status',
  'org.alice_card.kyc.start_label': 'Start',
  'organization.offboard_pending.alert':
    'Your employer will no longer offer Alice as of <strong>{offboardDate}</strong>. Please direct questions about pretax benefits to your HR team.',
  'organization.offboarded.alert':
    'Your employer is no longer offering Alice as of <strong>{offboardDate}</strong>. Please direct questions about pretax benefits to your HR team.',
  'organization.offboarded.heading': 'Alice is no longer available at {orgName}',
  'organization.onboarding.accept_employer_tos':
    'I accept the <employer-tos>Employer Terms of Service</employer-tos>',
  'organization.onboarding.company_info_heading': 'Company info',
  'organization.onboarding.excited_to_start':
    "We're excited to get your account set up and launch Alice Pretax Benefits to your team!",
  'organization.onboarding.first_step_account':
    'The first step is to set up your individual login with Alice as an adminstrator of your organization.',
  'organization.onboarding.get_started':
    "Get started below, and please don't hesitate to reach out to our team at <email-admin-support></email-admin-support> if you have any trouble.",
  'organization.onboarding.go_to_employee_dash':
    'Looking for your enrollment info? Go to your <employee-dashboard-link>employee dashboard</employee-dashboard-link>.',
  'organization.onboarding.go_to_org_admin_dash':
    'Looking for your company info? Go to your <org-admin-dashboard-link>organization dashboard</org-admin-dashboard-link>.',
  'organization.onboarding.go_to_org_or_ee_dash':
    'Not setting up a new organization with Alice? Go to your <org-admin-dashboard-link>current organization dashboard</org-admin-dashboard-link> or <employee-dashboard-link>employee dashboard</employee-dashboard-link>.',
  'organization.onboarding.heading': 'Welcome to Alice!',
  'organization.onboarding.locations.deletion_notice': 'Deleting logo...',
  'organization.onboarding.locations.submission_notice': 'Updating logo...',
  'organization.onboarding.locations.upload_success_notice': 'Logo saved!',
  'organization.onboarding.login': 'Log in instead.',
  'organization.onboarding.order_form_heading': 'Order Form',
  'organization.onboarding.payroll_connect_heading': 'Payroll connect',
  'organization.onboarding.payroll_connect_instructions':
    "We've emailed instructions for connecting your payroll provider to Alice. Please follow the instructions in that email before continuing.",
  'organization.onboarding.payroll_connect_instructions_completed':
    'Thanks for completing the payroll connection instructions. We have been notified and will contact you directly to finalize your onboarding.',
  'organization.onboarding.payroll_instructions_complete': 'Payroll connection ready',
  'organization.onboarding.set_up_company':
    'After your login is created, you can return to continue setting up your company, connect Alice to payroll, and add your billing and banking information.',
  'organization.onboarding.setup_account_heading': 'Administrator account',
  'organization.onboarding.setup_account_instructions':
    "First, we need to create a secure account for <strong>you</strong> — your email address will be your username and we'll confirm your phone number by text.",
  'payroll.finch.authentication_completed': 'Payroll access granted, thank you.',
  'payroll.finch.authentication_error_ocurred':
    'There was an error granting Payroll access, please try again.',
  'payroll.finch.authentication_iframe_closed': 'Payroll access was not granted.',
  'plaid.link_session.status.created':
    'We are currently waiting for card connection info from Plaid - it should be just a moment. If there was a problem, please try connecting again.',
  'plaid.link_session.status.failed':
    'It looks like there was a problem connecting your card through Plaid. Please try again.',
  'profile.address.city': 'City',
  'profile.address.note_billing_address':
    '<em>Note:</em> this address is used as your Alice Card billing address',
  'profile.address.optional': '(optional)',
  'profile.address.state': 'State',
  'profile.address.street_address': 'Street address',
  'profile.address.zipcode': 'Zip',
  'profile.birthdate.label': 'Birthdate',
  'profile.cellphone.validation.required': 'Please enter your cellphone number',
  'profile.cellphone.validation.required_length': 'Number must be 10 digits',
  'profile.email_label': 'Email address',
  'profile.email.placeholder': 'example@email.com',
  'profile.email.validation.invalid_format': 'Invalid email address',
  'profile.email.validation.required': 'Email is required',
  'profile.first_name_label': 'First name',
  'profile.heading': 'Profile',
  'profile.index.documents': 'Documents',
  'profile.index.language': 'Language',
  'profile.index.mailing_address': 'Mailing address',
  'profile.index.what_can_be_managed': 'Manage your Alice account information',
  'profile.last_name_label': 'Last name',
  'profile.name.validation.no_numbers': 'Name cannot contain numbers',
  'profile.name.validation.required': 'Please enter your name',
  'profile.password_confirmation_label': 'Confirm password',
  'profile.password_label': 'Password',
  'profile.password.validation.required': 'Password is required',
  'profile.preferred_name_label': 'Preferred name',
  'profile.tel_label': 'Mobile phone number',
  'reenroll.landing_spending_connected.almost_finished': "You're almost finished!",
  'reenroll.landing_spending_connected.select_option_below_to_reenroll':
    'Please connect a bank account below to start your Alice re-enrollment.',
  'reenroll.landing_spending_connected.submit_reenrollment':
    'Set a new password for your <strong>{username}</strong>  account and submit your re-enrollment next.',
  'reenroll.landing.purchases_while_unenrolled_not_eligible':
    'Purchases made before re-enrollment are not eligible.',
  'reenroll.landing.submit_reenrollment': 'Submit Re-Enrollment',
  'reenroll.landing.to_reenroll_password_update_required':
    'To re-enroll, please update your password and submit!',
  'reenroll.landing.welcome_back': 'Welcome back!',
  'spending.commuter_expenses.bus': 'Bus',
  'spending.commuter_expenses.ferry': 'Ferry',
  'spending.commuter_expenses.light_rail': 'Light rail',
  'spending.commuter_expenses.parking_meter': 'Parking meter',
  'spending.commuter_expenses.rideshare': 'Rideshare',
  'spending.commuter_expenses.streetcar': 'Streetcar',
  'spending.commuter_expenses.subway': 'Subway',
  'spending.commuter_expenses.train': 'Train',
  'spending.eligible_expenses.eligible_expenses_and_the_irs':
    'Eligible commuting expenses are defined by the IRS. For commuting benefits, this includes using any of the following to get to or from work:',
  'spending.eligible_expenses.whats_is_an_eligible_expense': 'What is an eligible expense?',
  'spending.heading': 'Spending',
  'spending.missing_transaction.brief_description': 'the name of the seller',
  'spending.missing_transaction.constraint_on_mass_transit':
    'Mass transit claims can only be made from connected card transactions',
  'spending.missing_transaction.date_error':
    "Banks can take up to a week to report your eligible purchases to us. If you don't see a text from us after that time, let us know!",
  'spending.missing_transaction.expense_description':
    'This purchase was made at <description-input></description-input>.',
  'spending.missing_transaction.expense_details':
    'On <date-input></date-input> I paid <amount-input></amount-input> for a <pretax-category-input></pretax-category-input> expense.',
  'spending.missing_transaction.i_paid_with': 'I paid with',
  'spending.missing_transaction.instructions_to_submit':
    "If we didn't text you about a transaction from more than a week ago and you don't see it on your dashboard, give us some info and we'll get it added for you.",
  'spending.missing_transaction.issued_by': '{bankName} card',
  'spending.missing_transaction.my_connected_card': 'an Alice-connected card',
  'spending.missing_transaction.payment_card.alice_card': 'Alice Card',
  'spending.missing_transaction.personal_card': 'a card not connected to Alice',
  'spending.missing_transaction.submit_expense': 'Submit expense',
  'spending.missing_transaction.submitting_expense': 'Submitting expense claim...',
  'spending.receipt.attach_receipt': 'Attach receipt',
  'spending.receipt.context_around_receipt':
    "To be reimbursed for this expense, you must submit a copy of your receipt, We'll use the receipt to confirm eligibility and process the reimbursement.",
  'spending.receipt.image_instructions': 'Please upload an image of your receipt',
  'spending.receipt.receipt': 'Receipt',
  'spending.receipt.receipt_attachment_compatibility': 'JPG, PNG, or PDF. Size limit: 10MB',
  'spending.receipt.take_a_picture': 'Take a picture',
  'spending.spending': 'Spending',
  'spending.summary.category.over_limit':
    'I get pretax savings on up to <strong>{limit}</strong> of mass transit every month. So far in <strong>{monthName}</strong>, I’ve confirmed savings on <strong>{spentSoFar}</strong>. Alice will process the excess next month, up to the <strong>{limit}</strong> limit.',
  'spending.summary.category.under_limit':
    'I get pretax savings on up to <strong>{limit}</strong> of {pretaxCategory} every month. So far in <strong>{monthName}</strong>, I’ve confirmed savings on <strong>{spentSoFar}</strong> and have <strong>{available}</strong> available to confirm this month. Any more spend I confirm will apply to next month’s amount.',
  'transactions.a_category': 'a category',
  'transactions.amount': 'amount',
  'transactions.category': 'Category',
  'transactions.date': 'date',
  'transactions.empty_transaction_list.benefits_started':
    'Your benefits started on {benefitsStartDate} - when we find eligible spending after that date, it will show up here.',
  'transactions.empty_transaction_list.no_card_connected':
    "You don't have any cards connected. To see eligible transactions, connect a bank card or request an Alice card to use.",
  'transactions.empty_transaction_list.no_eligible_transactions':
    "We haven't seen any eligible expenses from your connected card(s) in the selected date range. Please submit a missing transaction if you've made eligible purchases, or choose another date range.",
  'transactions.empty_transaction_list.no_transactions_and_no_cards':
    "You don't have any cards connected. To see eligible transactions, connect a bank card and start spending.",
  'transactions.empty_transaction_list.no_transactions_but_has_alice_card':
    "You don't have any transactions to view. Please use your Alice Card or connect a bank card and start spending.",
  'transactions.empty_transaction_list.no_transactions_but_has_cards':
    "You don't have any transactions to view.  Please make sure you pay for eligible transit expenses on your connected card(s).",
  'transactions.empty_transaction_list.no_transactions_but_has_gated_alice_card':
    "You don't have any transactions to view. We will text you when your Alice Card is ready to use, but you can also connect a bank card and start spending.",
  'transactions.empty_transaction_list.no_transactions_but_personal_card':
    "You don't have any transactions to view.  Please make sure you pay for eligible transit expenses on your connected card(s), or request an Alice card to use.",
  'transactions.empty_transaction_list.no_transactions_but_personal_card_only':
    "You don't have any transactions to view. Please make sure you pay for eligible transit expenses on your connected card(s).",
  'transactions.empty_transaction_list.no_transactions_for_filter':
    'There are no transactions that match the selected filters. Please update the filter settings.',
  'transactions.matching_transactions': 'matching transactions',
  'transactions.payment_type.alice_card': 'Your Alice card',
  'transactions.payment_type.cash': 'cash',
  'transactions.payment_type.personal_card': 'a connected personal card',
  'transactions.pretax_categories.dental': 'Dental',
  'transactions.pretax_categories.dependent_care': 'Dependent care',
  'transactions.pretax_categories.healthcare': 'Healthcare',
  'transactions.pretax_categories.mass_transit': 'Mass transit',
  'transactions.pretax_categories.not_eligible': 'Not eligible',
  'transactions.pretax_categories.parking': 'Parking',
  'transactions.pretax_categories.vision': 'Vision',
  'transactions.spending.auto_confirmed': 'Alice automatically confirmed this for you on {autoConfirmedOn}.',
  'transactions.spending.auto_confirmed_disconfirm':
    'You can mark this as <disconfirm-action>"No - this was not for my work commute"</disconfirm-action> if we got that wrong.',
  'transactions.spending.disconfirm.success': 'Transaction marked ineligible',
  'transactions.spending.expense_with_status': 'Expense {status}',
  'transactions.spending.transaction_record_details':
    'On {date}, you spent <amount></amount>  on an eligible {category} expense.',
  'transactions.spending.you_paid_with': 'You paid with <payment_type></payment_type>.',
  'transactions.status.approved.label': 'Expense approved for reimbursement',
  'transactions.status.approved.summary': 'Your expense has been approved for reimbursement.',
  'transactions.status.approved.title': 'Approved',
  'transactions.status.confirmed.label': 'Expense confirmed for reimbursement',
  'transactions.status.confirmed.summary': 'Your expense has been confirmed for reimbursement.',
  'transactions.status.confirmed.title': 'Confirmed',
  'transactions.status.deducted.label': 'Expense deducted from paycheck',
  'transactions.status.deducted.summary': 'Your expense was deducted from your paycheck',
  'transactions.status.deducted.title': 'Deducted',
  'transactions.status.deduction_canceled.label': 'Paycheck deduction canceled',
  'transactions.status.deduction_canceled.summary': 'Your expense was deducted from your paycheck',
  'transactions.status.deduction_canceled.title': 'Canceled deduction',
  'transactions.status.denied.label': 'Expense request denied',
  'transactions.status.denied.summary':
    'Alice determined that the submitted expense does not qualify as an eligible expense',
  'transactions.status.denied.title': 'Denied',
  'transactions.status.disconfirmed.label': 'Expense was confirmed, then later marked ineligible',
  'transactions.status.disconfirmed.summary':
    'The transaction was confirmed, then later marked ineligible. If you were already reimbursed for this expense, the amount will be deducted from future reimbursements.',
  'transactions.status.disconfirmed.title': 'Marked Ineligible',
  'transactions.status.reimbursed_deduction_pending.label': 'Expense paid',
  'transactions.status.reimbursed_deduction_pending.summary':
    'Your expense will be deducted from your next paycheck',
  'transactions.status.reimbursed_deduction_pending.title': 'Pending',
  'transactions.status.reimbursed.label': 'Reimbursed',
  'transactions.status.reimbursed.summary': 'Your expense was reimbursed on your {dateRange} paycheck',
  'transactions.status.reimbursed.title': 'Reimbursed',
  'transactions.status.rejected.label': 'Expense was marked as ineligible',
  'transactions.status.rejected.summary':
    'You indicated that this transaction was not for an eligible expense',
  'transactions.status.rejected.title': 'rejected',
  'transactions.status.submitted.label': 'Expense submitted for approval',
  'transactions.status.submitted.summary': 'Your expense has been submitted and is awaiting approval.',
  'transactions.status.submitted.title': 'submitted',
  'transactions.status.unconfirmed.label': 'Expense not yet confirmed',
  'transactions.status.unconfirmed.prompt': 'Is this expense for {pretaxCategory}?',
  'transactions.status.unconfirmed.summary':
    'Your expense is unconfirmed - confirm it from the transactions list in order to receive a reimbursement.',
  'transactions.status.unconfirmed.title': 'Unconfirmed',
  'transactions.transaction': 'Transaction',
  'transactions.transaction_list_item.auto_confirmed': 'Auto-confirmed - View Details',
  'transactions.transaction_list_item.estimated_savings.abbr': 'est. savings',
  'transactions.transaction_list_item.status': 'Status: {status}',
  'transactions.transaction_list.are_these_for': 'Are these transactions for',
  'transactions.transaction_list.did_we_missing_something': 'Did we miss something?',
  'transactions.transaction_list.eligible_commuter_expenses': 'eligible commuter expenses?',
  'transactions.transaction_list.is_it_for': 'Is this transaction for',
  'transactions.transaction_list.submit_missing_transaction': 'Submit missing transaction',
  'transactions.transactions': 'Transactions',
}
