import {
  ActionLayout,
  Box,
  Button,
  Container,
  ExternalLink,
  Grid,
  Link,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { ReenrollingWithoutSpendingConnectionsGreeting } from '../../enrollment/reenrollment/ReenrollingWithoutSpendingConnectionsGreeting'
import { AliceCardConnectCard } from './alice/AliceCardConnectCard'
import { BankingConnectionConnectCard } from './personal/BankingConnectionConnectCard'
import { useAliceCardFeatureEnabled, useSpendingConnectionSummary } from './useQuerySpendingConnections'

/**
 * Basic responsive layout linking to card connect UX
 */
export const CardConnectionsList = () => {
  const allowAliceCardRoute = useAliceCardFeatureEnabled()

  return (
    <Box component={Grid} container spacing={2} rowGap={2}>
      {allowAliceCardRoute && <AliceCardConnectCard />}
      <BankingConnectionConnectCard />
    </Box>
  )
}

const FinishCardConnectButton = () => {
  const { hasConnectedBankingConnections, hasAliceCards, error } = useSpendingConnectionSummary()

  const allowFinish = Boolean(hasConnectedBankingConnections || hasAliceCards || error)
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      component={Link}
      to="/"
      disabled={!allowFinish}
      data-testid="finish-card-connect"
    >
      <FormattedMessage id="common.continue" />
    </Button>
  )
}

export const CardsIndex = () => {
  const allowAliceCardRoute = useAliceCardFeatureEnabled()

  if (allowAliceCardRoute === undefined) return null // wait for API data that determines route validity
  if (!allowAliceCardRoute) return <Navigate to="personal" replace />

  return (
    <PageBody>
      <Container>
        <ReenrollingWithoutSpendingConnectionsGreeting />
        <Typography fontWeight="bold" gutterBottom>
          <FormattedMessage id="connections.all_connections.both.byline" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="connections.all_connections.both.description" />
        </Typography>

        <CardConnectionsList />

        <ActionLayout
          primary={<FinishCardConnectButton />}
          secondary={
            <Button
              component={ExternalLink}
              target="_blank"
              href="https://www.thisisalice.com/privacy-policy/"
            >
              <FormattedMessage id="common.learn_more_about_security" />
            </Button>
          }
        />
      </Container>
    </PageBody>
  )
}
