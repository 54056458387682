import {
  ActionLayout,
  Alert,
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemLink,
  ListItemText,
  PageBody,
  Paper,
  Skeleton,
  Typography,
  styled,
} from '@alice-financial/pretext-ui'

import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import SearchIcon from '@mui/icons-material/Search'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEnrollmentStatus } from '../../../enrollment/useEnrollmentStatus'
import { BankingConnectionPlatform, PlaidLinkSessionStatusEnum } from '../../../graphql/generated.types'
import { useOffboardDate } from '../../../organization/offboarding/orgOffboardUtils'
import { useSearchParamsRedirectPath } from '../../../routes/routeUtils'
import { LinkSessionFragment } from '../gql/connectedCardsQuery_gen'
import { useSearchBankingInstitutionsQuery } from '../gql/searchBankingInstitutions_gen'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { BankingConnectionList } from './BankingConnectionList'
import bankLogosUrl from './assets/bank-logos.png'
import { getIsConnectedToInstitution, isAvailable } from './connect/bankingConnectionUtils'
import { useNavigateToPlaidLink } from './connect/usePlaidLink'

type FinicityInstitution = {
  id: number
  name: string
  logoUrl?: string | null
}

const FinicityItem = ({ id, name, logoUrl }: FinicityInstitution) => {
  const {
    data: { bankingConnections },
  } = useQuerySpendingConnections()
  const hasConnected = bankingConnections.some(getIsConnectedToInstitution(id))
  if (hasConnected) return null
  const icon = logoUrl ? (
    <img src={logoUrl} width={42} />
  ) : (
    <AccountBalanceIcon fontSize="large" color="secondary" width={42} height={42} />
  )
  return (
    <ListItem divider disableGutters disablePadding>
      <ListItemLink to={`${id}/connect/finicity`}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemLink>
    </ListItem>
  )
}

const PlatformConnect = styled(Paper)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(2),
  height: '100%',
}))

export const PERSONAL_CARDS_ROUTE = '/cards/personal'

const ProvidersIntro = () => (
  <>
    <Typography gutterBottom>
      <FormattedMessage id="connections.we_work_with_providers" />
    </Typography>
    <ul>
      <li>
        <Typography>
          <FormattedMessage id="connections.scan_for_eligible" />
        </Typography>
      </li>
      <li>
        <Typography>
          <FormattedMessage id="connections.we_find_we_text" />
        </Typography>
      </li>
    </ul>
  </>
)

const ReenrollmentIntro = () => (
  <>
    <Typography variant="h1" gutterBottom>
      <FormattedMessage id="reenroll.landing.welcome_back" />
    </Typography>
    <Alert severity="info">
      <Typography>
        <FormattedMessage id="reenroll.landing_spending_connected.select_option_below_to_reenroll" />
      </Typography>
    </Alert>
    <ProvidersIntro />
  </>
)

const HasConnectedCardsIntro = () => (
  <>
    <Typography variant="h1" gutterBottom>
      <FormattedMessage id="cards.personal.my_connected_bank_accounts" />
    </Typography>
    <ProvidersIntro />
  </>
)

type HasRecentLinkSessionIntroProps = {
  linkSession: LinkSessionFragment
}
const HasRecentLinkSessionIntro = ({ linkSession }: HasRecentLinkSessionIntroProps) => (
  <>
    <Typography variant="h1" gutterBottom>
      <FormattedMessage id="cards.personal.my_connected_bank_accounts" />
    </Typography>
    {linkSession.status === PlaidLinkSessionStatusEnum.Created && (
      <Alert severity="info">
        <FormattedMessage id="plaid.link_session.status.created" />
      </Alert>
    )}
    {linkSession.status === PlaidLinkSessionStatusEnum.Failed && (
      <Alert severity="info">
        <FormattedMessage id="plaid.link_session.status.created" />
      </Alert>
    )}
    <ProvidersIntro />
  </>
)
const ConnectFirstCardIntro = () => (
  <>
    <Typography variant="h1" gutterBottom>
      <FormattedMessage id="cards.personal.select_your_bank" />
    </Typography>
    <ProvidersIntro />
  </>
)

/**
 * This screen presents the user with their currently-connected bank accounts/cards and
 * allows them to connect new banks/cards so that we can watch their transactions for
 * eligible expenses.
 *
 */
export const BankingConnections = () => {
  const returnPath = useSearchParamsRedirectPath('/')
  const offboardDate = useOffboardDate()
  const enrollmentStatus = useEnrollmentStatus()
  const navigateToPlaidLink = useNavigateToPlaidLink()

  const {
    data: { bankingConnections, recentLinkSession },
    isLoading,
  } = useQuerySpendingConnections()

  const { data: finicityInstitutionsData } = useSearchBankingInstitutionsQuery({
    preferredPlatform: BankingConnectionPlatform.Finicity,
  })
  const finicityInstitutions = finicityInstitutionsData?.searchBankingInstitutions || []
  if (offboardDate && offboardDate < new Date()) return null

  const hasAvailableCards = bankingConnections.filter(isAvailable).length > 0
  const needsReenrollment = enrollmentStatus === 'needs_reenrollment_and_spending_connection'
  const IntroMessaging = () => {
    if (enrollmentStatus === undefined) return null
    if (needsReenrollment) return <ReenrollmentIntro />
    if (recentLinkSession && recentLinkSession.status !== PlaidLinkSessionStatusEnum.Completed) {
      return <HasRecentLinkSessionIntro linkSession={recentLinkSession} />
    }
    if (hasAvailableCards) return <HasConnectedCardsIntro />
    return <ConnectFirstCardIntro />
  }

  const startPlaidLink = () => navigateToPlaidLink({})

  return (
    <PageBody>
      <Container>
        {isLoading ? <Skeleton /> : <IntroMessaging />}
        <BankingConnectionList bankingConnections={bankingConnections} />

        {hasAvailableCards && (
          <Typography variant="h2" gutterBottom sx={{ mt: 3 }}>
            <FormattedMessage id="connections.connect_another" />
          </Typography>
        )}
        <Typography gutterBottom>
          <FormattedMessage id="connections.select_from_list" />
        </Typography>
        <PlatformConnect>
          <List disablePadding>
            {finicityInstitutions.map(({ id, name, logoUrl }) => (
              <FinicityItem key={id} id={id} name={name} logoUrl={logoUrl} />
            ))}
            <ListItem divider disableGutters disablePadding>
              <ListItemButton onClick={startPlaidLink} data-testid="connect-all-other-banks">
                <ListItemIcon sx={{ alignSelf: 'flex-start' }}>
                  <SearchIcon color="primary" fontSize="large" />
                </ListItemIcon>
                <Box width="100%" mt="0.3em">
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold" color="text.primary">
                        <FormattedMessage id="connections.all_other_banks" />
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" component="p" gutterBottom>
                        <FormattedMessage id="connections.connect_through_plaid" />
                      </Typography>
                    }
                  />
                  <img width={200} src={bankLogosUrl} />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </PlatformConnect>

        <ActionLayout
          mt={0}
          primary={
            <Button
              variant="contained"
              color="primary"
              disabled={!hasAvailableCards}
              component={Link}
              to={returnPath}
            >
              <FormattedMessage id="common.done" />
            </Button>
          }
        />
      </Container>
    </PageBody>
  )
}
