import {
  Box,
  Button,
  Container,
  PageBody,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { dateFormat } from '../../../../utils/formatters/dateFormat'
import { formatCentsAsDollars } from '../../../../utils/formatters/moneyFormat'
import { ReadablePaymentType, ReadableTxnCategory } from '../txnUtils'
import {
  AliceCardTransactionStatus,
  CashTransactionStatus,
  PersonalCardTransactionStatus,
  Transaction,
  TransactionStatus,
  TxnProps,
} from '../types'
import { useConfirmTransactions } from '../useConfirmTransaction'
import { useTransactionDetailQuery } from '../useTransactionDetailQuery'

const DISPLAY_STATUSES: Array<TransactionStatus> = ['submitted', 'confirmed']
const width = `${DISPLAY_STATUSES.length * 15}%`
const minWidth = DISPLAY_STATUSES.length * 48 // steps will overflow the width if it gets any narrower

type FlowSteps<TStatus> = Record<
  'happy' | 'sad' | 'happy_unknowable_reimbursement' | 'disconfirmed',
  Array<TStatus>
>
const PERSONAL_CARD_STEPS: FlowSteps<PersonalCardTransactionStatus> = {
  happy: ['unconfirmed', 'confirmed', 'reimbursed'],
  happy_unknowable_reimbursement: ['unconfirmed', 'confirmed_final'],
  sad: ['unconfirmed', 'rejected'],
  disconfirmed: ['unconfirmed', 'confirmed', 'disconfirmed'],
}
const CASH_STEPS: FlowSteps<CashTransactionStatus> = {
  happy: ['submitted', 'approved'],
  happy_unknowable_reimbursement: ['submitted', 'approved_final'],
  sad: ['submitted', 'denied'],
  disconfirmed: ['submitted', 'approved', 'disconfirmed'],
}
const ALICE_STEPS: FlowSteps<AliceCardTransactionStatus> = {
  // happy: ['reimbursed_deduction_pending', 'deducted'],
  happy: ['reimbursed_deduction_pending'], // TODO: add 'deductionStatus' to transaction API response to determine deduction status
  happy_unknowable_reimbursement: ['reimbursed_deduction_pending'],
  sad: [],
  disconfirmed: [],
}
type TransactionStatusContent = {
  title: string
  label: string
  summary: string
  steps: Array<TransactionStatus>
  error?: boolean
}
const getStatusContent = (intl: IntlShape, txn?: Transaction): TransactionStatusContent | null => {
  if (!txn) return null

  switch (txn.status.value) {
    // PersonalCardTransactionStatus
    case 'unconfirmed':
      return {
        title: intl.formatMessage({ id: 'transactions.status.unconfirmed.title' }),
        label: intl.formatMessage({ id: 'transactions.status.unconfirmed.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.unconfirmed.summary' }),
        steps: PERSONAL_CARD_STEPS.happy,
      }
    case 'confirmed_final':
      return {
        title: intl.formatMessage({ id: 'transactions.status.confirmed.title' }),
        label: intl.formatMessage({ id: 'transactions.status.confirmed.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.confirmed.summary' }),
        steps: PERSONAL_CARD_STEPS.happy_unknowable_reimbursement,
      }
    case 'confirmed':
      return {
        title: intl.formatMessage({ id: 'transactions.status.confirmed.title' }),
        label: intl.formatMessage({ id: 'transactions.status.confirmed.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.confirmed.summary' }),
        steps: PERSONAL_CARD_STEPS.happy,
      }
    case 'rejected':
      return {
        title: intl.formatMessage({ id: 'transactions.status.rejected.title' }),
        label: intl.formatMessage({ id: 'transactions.status.rejected.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.rejected.summary' }),
        steps: PERSONAL_CARD_STEPS.sad,
        error: true,
      }
    case 'disconfirmed':
      return {
        title: intl.formatMessage({ id: 'transactions.status.disconfirmed.title' }),
        label: intl.formatMessage({ id: 'transactions.status.disconfirmed.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.disconfirmed.summary' }),
        steps: PERSONAL_CARD_STEPS.disconfirmed,
        error: true,
      }
    // CashTransactionStatus
    case 'submitted':
      return {
        title: intl.formatMessage({ id: 'transactions.status.submitted.title' }),
        label: intl.formatMessage({ id: 'transactions.status.submitted.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.submitted.summary' }),
        steps: CASH_STEPS.happy,
      }
    case 'approved_final':
      return {
        title: intl.formatMessage({ id: 'transactions.status.approved.title' }),
        label: intl.formatMessage({ id: 'transactions.status.approved.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.approved.summary' }),
        steps: CASH_STEPS.happy_unknowable_reimbursement,
      }
    case 'approved':
      return {
        title: intl.formatMessage({ id: 'transactions.status.approved.title' }),
        label: intl.formatMessage({ id: 'transactions.status.approved.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.approved.summary' }),
        steps: CASH_STEPS.happy,
      }
    case 'denied':
      return {
        title: intl.formatMessage({ id: 'transactions.status.denied.title' }),
        label: intl.formatMessage({ id: 'transactions.status.denied.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.denied.summary' }),
        steps: CASH_STEPS.sad,
        error: true,
      }
    case 'reimbursed_deduction_pending':
      return {
        title: intl.formatMessage({ id: 'transactions.status.reimbursed_deduction_pending.title' }),
        label: intl.formatMessage({ id: 'transactions.status.reimbursed_deduction_pending.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.reimbursed_deduction_pending.summary' }),
        steps: ALICE_STEPS.happy,
      }
    case 'deducted':
      return {
        title: intl.formatMessage({ id: 'transactions.status.deducted.title' }),
        label: intl.formatMessage({ id: 'transactions.status.deducted.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.deducted.summary' }),
        steps: ALICE_STEPS.happy,
      }
    case 'deduction_canceled':
      return {
        title: intl.formatMessage({ id: 'transactions.status.deduction_canceled.title' }),
        label: intl.formatMessage({ id: 'transactions.status.deduction_canceled.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.deduction_canceled.summary' }),
        steps: ALICE_STEPS.sad,
      }
    case 'reimbursed':
      return {
        title: intl.formatMessage({ id: 'transactions.status.reimbursed.title' }),
        label: intl.formatMessage({ id: 'transactions.status.reimbursed.label' }),
        summary: intl.formatMessage({ id: 'transactions.status.reimbursed.summary' }),
        steps: txn.paymentType === 'cash' ? CASH_STEPS.happy : PERSONAL_CARD_STEPS.happy,
      }
  }
}

const TransactionAutoconfirmation = ({ txn }: { txn: Transaction }) => {
  const { mutate: updateTxnConfirmation, isLoading, isSuccess } = useConfirmTransactions([txn.id])
  const disConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    updateTxnConfirmation({ isConfirmed: false })
  }

  const submitIsDisabled = isLoading || isSuccess
  const autoConfirmedAt = txn.spendEligibility.autoConfirmedAt
  if (!autoConfirmedAt) return null

  return (
    <>
      <Typography gutterBottom>
        <FormattedMessage
          id="transactions.spending.auto_confirmed"
          values={{
            autoConfirmedOn: dateFormat.long(new Date(autoConfirmedAt)),
          }}
        />
      </Typography>
      {txn.spendEligibility.confirmationStatus.value === 'confirmed' &&
        txn.spendEligibility.isDisconfirmable && (
          <FormattedMessage
            id="transactions.spending.auto_confirmed_disconfirm"
            values={{
              'disconfirm-action': (chunks) => (
                <Button variant="text" disabled={submitIsDisabled} color="error" onClick={disConfirm}>
                  {chunks.join()}
                </Button>
              ),
            }}
          ></FormattedMessage>
        )}
    </>
  )
}

const TransactionInfo = ({ txn, summary }: TxnProps & { summary: string }) => {
  const intl = useIntl()

  if (!txn) return null
  return (
    <>
      <Typography gutterBottom>
        <FormattedMessage
          id="transactions.spending.transaction_record_details"
          values={{
            date: dateFormat.long(txn.date),
            amount: () => (
              <Typography color="primary" fontWeight="bold" component="span">
                {formatCentsAsDollars(txn.amount)}
              </Typography>
            ),
            category: intl.formatMessage({ id: ReadableTxnCategory[txn.spendEligibility.pretaxCategory] }),
          }}
        />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage
          id="transactions.spending.you_paid_with"
          values={{ payment_type: () => intl.formatMessage({ id: ReadablePaymentType[txn.paymentType] }) }}
        />
      </Typography>
      <TransactionAutoconfirmation txn={txn} />
      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: '2rem' }}>
        <FormattedMessage id="common.status" />
      </Typography>
      <Typography gutterBottom>{summary}</Typography>
    </>
  )
}

type StatusIndicatorProps = { steps: Array<TransactionStatus>; activeStepIndex: number; error?: boolean }
const StatusIndicator = ({ steps, activeStepIndex, error }: StatusIndicatorProps) => (
  <Stepper activeStep={activeStepIndex} sx={{ width, minWidth, margin: '3rem auto 1rem' }}>
    {steps.map((stepLabel, stepIndex) => (
      <Step key={stepLabel}>
        <StepLabel error={stepIndex === activeStepIndex && error}>{stepLabel}</StepLabel>
      </Step>
    ))}
  </Stepper>
)

export const TxnDetail = () => {
  const { txnId } = useParams()
  if (!txnId) throw new Error('Transaction ID not supplied to TxnDetail')
  const { data: txn } = useTransactionDetailQuery(txnId)
  const intl = useIntl()
  const statusContent = getStatusContent(intl, txn)

  return (
    <PageBody>
      <Container>
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage id="transactions.transaction" />
        </Typography>
        <Typography variant="h1" gutterBottom>
          {txn?.status ? (
            <FormattedMessage
              id="transactions.spending.expense_with_status"
              values={{ status: statusContent?.title }}
            />
          ) : (
            <Skeleton />
          )}
        </Typography>
        <Box my={8}>
          {statusContent && (
            <StatusIndicator
              steps={statusContent?.steps}
              activeStepIndex={statusContent?.steps.findIndex((s) => s === txn?.status.value) ?? undefined}
              error={statusContent.error}
            />
          )}

          <Typography fontWeight="bold" textAlign="center">
            {statusContent?.label}
          </Typography>
        </Box>
        {txn && statusContent && <TransactionInfo txn={txn} summary={statusContent.summary} />}
      </Container>
    </PageBody>
  )
}
